import dataFetcher, { keyedDataFetcher } from "modules/dataFetcher";
import api from "services/api";
import store from "services/store";
import Validator from "services/validator";
import { Missing } from "services/validator/rules";
import { getSelectedCloudAccount } from "state/cluster/services/create";
import clusterFormActions from "../form";
import { generateCoxDeployment } from "../utils";

export const coxNodeValidator = new Validator();
const deploymentValidator = new Validator();
deploymentValidator.addRule(["name", "pops"], Missing({ message: () => " " }));
coxNodeValidator.addRule(["deployments"], deploymentValidator);
coxNodeValidator.addRule(["instanceType"], Missing());

export const coxClusterValidator = new Validator();
coxClusterValidator.addRule("sshKeys", Missing());
coxClusterValidator.addRule(
  "coxEdgeLoadBalancerConfig",
  function* (value, key) {
    yield {
      field: `${key}.pops`,
      result: Missing()(value.pops),
    };
  }
);

export const regionsFetcher = dataFetcher({
  selectors: ["cluster", "cox", "regions"],
  async fetchData() {
    const response = await api.get("v1/clouds/coxedge/regions");
    return response.regions;
  },
});

export const coxInstanceTypesFetcher = keyedDataFetcher({
  selectors: ["cluster", "cox", "instanceTypes"],
  async fetchData([_, _2, _3, region]) {
    const selectedRegion = regionsFetcher
      .selector(store.getState())
      .result.find((reg) => reg.code === region);
    if (!selectedRegion) {
      return [];
    }
    const response = await api.get(
      `v1/clouds/coxedge/regions/${selectedRegion.name}/instancetypes`
    );
    return response.instanceTypes;
  },
});

export const organizationsFetcher = dataFetcher({
  selectors: [
    "cluster",
    "cox",
    "organizations",
    (state) => state.forms?.cluster?.data?.credential,
  ],
  async fetchData([_, _1, _2, credential]) {
    const response = await api.get(
      `v1/clouds/coxedge/organizations?cloudAccountUid=${credential}`
    );
    return response.organizations;
  },
});

export const environmentsFetcher = dataFetcher({
  selectors: [
    "cluster",
    "cox",
    "environments",
    (state) => state.forms?.cluster?.data?.credential,
  ],
  async fetchData([_, _1, _2, credential]) {
    const response = await api.get(
      `v1/clouds/coxedge/environments?cloudAccountUid=${credential}`
    );
    return response.environments;
  },
});

export function fetchNodesConfigParams({ region }) {
  return function thunk(dispatch) {
    dispatch(regionsFetcher.fetch());

    if (!!region) {
      dispatch(coxInstanceTypesFetcher.key(region).fetch());
    }
  };
}

export function fetchClusterConfigParams() {
  return function thunk(dispatch) {
    dispatch(regionsFetcher.fetch());
    dispatch(organizationsFetcher.fetch());
    dispatch(environmentsFetcher.fetch());
  };
}

function selectCredentialEffect() {
  return async (dispatch, getState) => {
    const cloudAccount = getSelectedCloudAccount(getState());

    dispatch(
      clusterFormActions.batchChange({
        module: "cluster",
        updates: {
          organizationId: cloudAccount.spec.organizationId,
          environment: cloudAccount.spec.environment,
        },
      })
    );
  };
}

const effects = {
  selectCredentialEffect,
  fetchNodesConfigParams,
  fetchClusterConfigParams,
};

export function createDeploymentsActions({
  getDeploymentsSelector,
  formActions,
  module,
  name,
}) {
  return {
    onRegionChange(name, value) {
      return function thunk(dispatch, getState) {
        dispatch(coxInstanceTypesFetcher.key(value).fetch());
        dispatch(
          formActions.onChange({
            module,
            name: name,
            value,
          })
        );
      };
    },
    onAdd() {
      return function thunk(dispatch, getState) {
        const deployments = getDeploymentsSelector(getState());
        dispatch(
          formActions.onChange({
            module,
            name: `${name}.deployments`,
            value: [...deployments, generateCoxDeployment()],
          })
        );
      };
    },
    onDelete(path) {
      return (dispatch, getState) => {
        const deployments = [...getDeploymentsSelector(getState())];
        const pathParts = path.split(".");
        const deploymentIndex = pathParts[3];
        deployments.splice(deploymentIndex, 1);

        if (deployments.length === 0) {
          deployments.push(generateCoxDeployment());
        }

        dispatch(
          formActions.onChange({
            module,
            name: `${name}.deployments`,
            value: deployments,
          })
        );
      };
    },
  };
}

export function createNetworkRulesActions({
  getItems,
  formActions,
  module,
  name,
}) {
  return {
    onAdd(item) {
      return function thunk(dispatch, getState) {
        const items = getItems(getState());
        dispatch(
          formActions.onChange({
            module,
            name: name,
            value: [...items, item],
          })
        );
      };
    },
    onReplace(item, index) {
      return function thunk(dispatch, getState) {
        const items = [...getItems(getState())];
        items[index] = item;
        dispatch(
          formActions.onChange({
            module,
            name: name,
            value: items,
          })
        );
      };
    },
    onDelete(index) {
      return (dispatch, getState) => {
        const items = [...getItems(getState())];
        items.splice(index, 1);

        dispatch(
          formActions.onChange({
            module,
            name,
            value: items,
          })
        );
      };
    },
  };
}

export default effects;
