import React, { useMemo } from "react";
import styled from "styled-components";
import { createSelector } from "reselect";
import { useTranslation } from "react-i18next";
import store from "services/store";

import { presentLayer } from "utils/presenters";
import {
  concreteWhite,
  darkGray,
  lightMidGray,
  regentGray,
  white,
  winterMist,
} from "utils/constants/colors";
import { formatTimestampToGeneralDateFormat } from "utils/date";

import { createConnector } from "modules/binder";
import { ThreeColumnsLayout } from "modules/profileIDE/Layout";
import Header from "modules/profileIDE/components/FileList/Header";

import Switch from "components/common/Switch";
import LayerSelector from "components/common/ClusterProfilePreview";
import Tags from "components/common/Tags";
import { PROFILE_TYPE_TAGS, SCOPE_TAGS } from "utils/constants/clusterprofile";

const OverviewWrapper = styled.div`
  border-left: 1px solid ${lightMidGray};
  height: 100%;
  background-color: ${white};

  h3 {
    margin-bottom: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const DetailsOverview = styled.div`
  min-width: 300px;
  padding: 32px;
`;

const Divider = styled.div`
  width: 1px;
  border-right: 1px solid ${concreteWhite};
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  border-bottom: 1px solid ${concreteWhite};
`;

const Label = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${regentGray};
`;

const Value = styled.span`
  color: ${darkGray};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

const HeaderTag = styled.div`
  color: #0d1440;
  border-radius: 13px;
  max-height: 18px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.05em;
  margin-right: 4px;
  background: ${(props) => (props.color ? props.color : winterMist)};
`;

const HeaderTags = styled.div`
  display: flex;
`;

const connector = createConnector({
  selectors: {
    currentTab: (state) => state.toolbar.activeSelection,
    metadata: (state) => state.fileList?.metadata || {},
    layers: createSelector(
      (state) => state.fileList?.files || [],
      (state) => state.fileList?.entities || {},
      (files, entities) => {
        const treeData = files
          .map((fileGuid) => {
            const entity = entities[fileGuid];
            return presentLayer(entity);
          })
          .filter(Boolean);
        return [...treeData].reverse();
      }
    ),
  },
  actions: {
    selectLayer: (guid) => (dispatch, module) => {
      const fileType = module.state.fileList?.entities?.[guid]?.type;

      if (fileType === "child-manifest") {
        dispatch({
          type: module.actions.editor.switchMode,
          mode: "editor",
        });
      }

      dispatch({
        type: module.actions.fileList?.onFileSelect,
        fileGuid: guid,
      });

      store.dispatch(module.form.actions.init({ guid }));
      module.dispatch({
        type: module.actions.toolbar.onTabSelect,
        tab: "edit",
      });
    },
  },
});

function AppProfileTags({ profileType, scope }) {
  const tags = useMemo(() => {
    return [PROFILE_TYPE_TAGS[profileType], SCOPE_TAGS[scope]].filter(Boolean);
  }, [profileType, scope]);

  const renderTag = (data) => {
    if (!data) {
      return null;
    }
    return (
      <HeaderTag key={data.label} color={data.color}>
        {data.label}
      </HeaderTag>
    );
  };

  return <HeaderTags>{tags.map(renderTag)}</HeaderTags>;
}

export default connector.connect(
  ({ currentTab, metadata, layers, selectLayer }) => {
    const { t } = useTranslation();

    const overviewData = useMemo(() => {
      if (!metadata) {
        return [];
      }

      return [
        {
          key: "name",
          label: t("Name"),
          value: metadata.name,
        },
        {
          key: "description",
          label: t("Description"),
          value: metadata.annotations?.description || t("Not specified."),
        },
        {
          key: "tags",
          label: t("Tags"),
          value: metadata.labels ? (
            <Tags tags={metadata?.labels} />
          ) : (
            t(t("n/a"))
          ),
        },
        {
          key: "createdOn",
          label: t("Created On"),
          value: formatTimestampToGeneralDateFormat(metadata.creationTimestamp),
        },
        {
          key: "lastModifiedTimestamp",
          label: "Last Modified",
          value: formatTimestampToGeneralDateFormat(
            metadata.lastModifiedTimestamp
          ),
        },
      ];
    }, [metadata, t]);

    function renderDescription({ key, label, value }) {
      return (
        <Description key={key}>
          <Label>{label}</Label>
          <Value>{value}</Value>
        </Description>
      );
    }

    return (
      <Switch value={currentTab}>
        <Switch.Case value="overview">
          <ThreeColumnsLayout.Editor>
            <OverviewWrapper>
              <Header>{t("Overview")}</Header>
              <ContentWrapper>
                <DetailsOverview>
                  <AppProfileTags
                    scope={metadata?.annotations?.scope}
                    profileType="appProfile"
                  />
                  {overviewData.map(renderDescription)}
                </DetailsOverview>
                <Divider />
                <LayerSelector
                  layers={layers}
                  tabsOnRight
                  hasBackground={false}
                  selectLayer={selectLayer}
                />
              </ContentWrapper>
            </OverviewWrapper>
          </ThreeColumnsLayout.Editor>
          <ThreeColumnsLayout.Files />
        </Switch.Case>
      </Switch>
    );
  }
);
