import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { SPECTRO_FOLDER_PREFIX, SPECTRO_TAG } from "./constants";
import {
  mergeYaml,
  wrapSpecialValues,
  removeWrappedSpecialValues,
} from "./yaml";
import * as YAML from "yaml";
import { getTerminalKeys } from "./objects";
import { get, set } from "lodash";
import orderBy from "lodash/orderBy";
import sumBy from "lodash/sumBy";
import moment from "moment";
import { COST_CHART_COLORS, lightMidGray, navy } from "./constants/colors";
import { extractUbuntuAdvantagePresetOptions } from "utils/yaml";
import { OPERATORS_MAPPING, ALL_OPERATORS } from "utils/constants/filters";
import { TENANT_LEVEL_CLUSTERS_PROJECT } from "./constants";

const presetsCommentRegex = /^(# spectrocloud.com\/enabled-presets:).*/gm;

export function parseTagsForInput(tags) {
  return ((tags && Object.keys(tags)) || []).map((key) => {
    const value = tags[key];
    if (value !== SPECTRO_TAG) {
      return `${key}: ${value}`;
    }

    return key;
  });
}
export function parseLabelsForInput(labels) {
  return ((labels && Object.keys(labels)) || []).map((key) => {
    const value = labels[key];
    return `${key}:${value}`;
  });
}

export function flattenObjectKeys(data = {}) {
  let flattenedObject = {};

  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (value && typeof value === "object") {
      let flatObject = flattenObjectKeys(value);
      Object.keys(flatObject).forEach((flatObjectKey) => {
        const flatObjectValue = flatObject[flatObjectKey];
        flattenedObject[key + "." + flatObjectKey] = flatObjectValue;
      });
    } else {
      flattenedObject[key] = value;
    }
  });

  return flattenedObject;
}

export function parseYAMLValues(values = "", presetValues) {
  if (!presetValues) {
    return;
  }

  try {
    const { wrappedValues, wrappedContent } = wrapSpecialValues([values]);

    const removePaths = presetValues.remove || [];
    const parsedYaml = YAML.parseDocument(wrappedContent[0], { strict: false });
    removePaths.forEach((path) => {
      const pathArray = path.split(".");
      const keyExists = parsedYaml.hasIn(pathArray);

      if (keyExists) {
        parsedYaml.deleteIn(pathArray);
        const parentPath = pathArray.slice(0, -1);
        const node = parsedYaml.getIn(parentPath);
        if (node?.items && node.items.length === 0) {
          parsedYaml.setIn(parentPath, null);
        }
      }
    });

    const addValues = presetValues.add?.replace(/\u21b5/g, "\n") || "";
    const mergedYaml = mergeYaml(
      removeWrappedSpecialValues(parsedYaml.toString(), wrappedValues),
      addValues
    );
    const output = mergedYaml.toJS();

    if (JSON.stringify(output) === "{}") {
      return parsedYaml.toString({ nullStr: "" });
    }
    return mergedYaml.toString();
  } catch (err) {
    console.error(err);
  }
}

// presets changes
// a ----- changes values ----> a' ---- applies preset1 ---> a''
// a'' ------ applies preset2 ====> a''' - means that we need the state
// in which a' was before applying the preset1 on top of which we
// apply the preset2
// unapplying a preset means that we remove what has been added
// and add what was removed from preset1 populated with the pack defaults
export function getPreviousPresetDelta({
  presetValues,
  presets,
  group,
  values,
  defaultValues,
}) {
  let prevPreset = presetValues[group];
  if (prevPreset) {
    prevPreset = presets.find(
      (preset) => preset.group === group && preset.value === prevPreset
    );
  }

  if (!prevPreset) {
    return "";
  }

  const defaults = YAML.parseDocument(defaultValues).toJS();
  const removedDefaults = (prevPreset.remove || []).reduce(
    (accumulator, key) => {
      const path = key.split(".");
      const value = get(defaults, path);
      if (value !== undefined) {
        set(accumulator, path, value);
      }

      return accumulator;
    },
    {}
  );
  return parseYAMLValues(values, {
    remove: getTerminalKeys(YAML.parseDocument(prevPreset.add).toJS()),
    add: YAML.stringify(removedDefaults),
  });
}

export function getDefaultPresetsFromValues(
  values = "",
  isUbuntuAdvantageDisplayed = false
) {
  const newValues = values?.replace(/\u21b5/g, "\n") || "";
  const comment = newValues.match(presetsCommentRegex)?.[0];
  const presetValues =
    comment && comment.trim().split("spectrocloud.com/enabled-presets:")?.[1];
  let presets = {};
  if (presetValues) {
    const groups = presetValues.split(",");
    presets = groups.reduce((acc, group) => {
      const [key, value] = group.trim().split(":");
      if (value) {
        return {
          ...acc,
          [key]: value.trim(),
        };
      }
      return {
        ...acc,
        "": key,
      };
    }, {});
  }

  return isUbuntuAdvantageDisplayed
    ? {
        ...presets,
        ubuntuAdvantage: {
          ...(presets.ubuntuAdvantage || {}),
          ...extractUbuntuAdvantagePresetOptions(values),
        },
      }
    : presets;
}

export function getPackValuesWithoutPresetsComment(values = "") {
  const newValues = values?.replace(/\u21b5/g, "\n");
  return (newValues?.replace(presetsCommentRegex, "") || "").trim();
}

export function getPackValuesWithPresetsComment(values = "", presetOptions) {
  const presets = omit(presetOptions, ["ubuntuAdvantage"]);

  if (isEmpty(presets)) {
    return (values || "").trim();
  }

  const enabledPresets = "# spectrocloud.com/enabled-presets: ";
  const presetValues = Object.keys(presets)
    .reduce((acc, key) => {
      if (key === "") {
        return [...acc, presets[key]];
      }
      return [...acc, `${key}:${presets[key]}`];
    }, [])
    .join(",");
  const comment = `${enabledPresets}${presetValues}`;
  return `${comment}\n${values}`.trim();
}

export function getVsphereFolderPayload(formData) {
  return formData.useFolderSufix
    ? `${formData.folder}/${SPECTRO_FOLDER_PREFIX}${formData.name}`
    : formData.folder;
}

export function trim(data = {}, fields) {
  let keys = fields;

  if (!Array.isArray(fields)) {
    keys = [fields];
  }

  return keys.reduce(
    (acc, field) => {
      if (typeof acc[field] === "string") {
        acc[field] = acc[field].trim();
      }
      return acc;
    },
    { ...data }
  );
}

export function getBoolean(value) {
  if (typeof value === "string") {
    return value.toLowerCase() === "true";
  }
  return !!value;
}

export function extractPath(obj, path) {
  let currentPath = path || "";
  const objectKeys = Object.keys(obj);
  if (typeof obj !== "object") {
    return currentPath;
  }

  const lastKey = objectKeys[objectKeys.length - 1];

  if (objectKeys.length > 0) {
    currentPath = currentPath ? `${currentPath}.["${lastKey}"]` : lastKey;
    return extractPath(obj[lastKey], currentPath);
  }

  return currentPath;
}

export function getCronScheduleValue(schedule, occurrence) {
  if (!schedule || schedule === "never") {
    return "";
  }
  return schedule === "custom" ? occurrence || "* * * * *" : schedule;
}

export function getArrayFromQuery(data) {
  if (!data) {
    return [];
  }
  if (!Array.isArray(data)) {
    return [data];
  }
  return data;
}

export function getSortedDataCosts({ data, getY }) {
  const dataCopy = [...data];
  const indexOfProjectLevelClusters = dataCopy.findIndex(
    (data) => data?.entity?.uid === TENANT_LEVEL_CLUSTERS_PROJECT.uid
  );
  const projectLevelClustersData = dataCopy[indexOfProjectLevelClusters];

  if (indexOfProjectLevelClusters > 0) {
    dataCopy.splice(indexOfProjectLevelClusters, 1);
  }

  const orderedDescData = orderBy(dataCopy, (item) => sumBy(item.data, getY), [
    "desc",
  ]);

  return indexOfProjectLevelClusters > 0
    ? [projectLevelClustersData, ...orderedDescData]
    : orderedDescData;
}

export function cumulateOtherCosts({ items, getY, xFormat }) {
  const data = (items || []).reduce((acc, item) => {
    const coordinates = getChartDataCoordinates({
      data: item.data,
      getY,
      xFormat,
    });

    coordinates.forEach((coord) => {
      if (coord.y === null) {
        acc[coord.x] = null;
        return;
      }

      acc[coord.x] = (acc[coord.x] || 0) + coord.y;
    });
    return acc;
  }, {});

  return Object.keys(data).map((key) => ({
    x: key,
    y: data[key],
  }));
}

export function getChartDataCoordinates({ data, getY, xFormat = "D MMM" }) {
  return orderBy(data, (item) => item.timestamp).map((item) => {
    const msTimestamp = Math.floor(item.timestamp / 1000000);
    const x = moment(msTimestamp).format(xFormat);
    const y = getY(item);
    return {
      x,
      y,
    };
  });
}

export function getSortedCumulativeCosts({ data, getY }) {
  function getLatestValue(dataItem) {
    return dataItem.reverse().find((item) => getY(item) !== null);
  }

  return [...data].sort((item1, item2) => {
    if (item1?.entity?.uid === TENANT_LEVEL_CLUSTERS_PROJECT.uid) {
      return -1;
    }
    if (item2?.entity?.uid === TENANT_LEVEL_CLUSTERS_PROJECT.uid) {
      return 1;
    }

    return (
      getY(getLatestValue([...item2.data])) -
      getY(getLatestValue([...item1.data]))
    );
  });
}

export function parseResourcesChartData({
  result,
  getY,
  xFormat,
  isCumulative = false,
  threshold = 5,
}) {
  if (!result?.length || !result.some((item) => item?.data?.length > 0)) {
    return null;
  }

  const sortedItems = isCumulative
    ? getSortedCumulativeCosts({ data: result, getY })
    : getSortedDataCosts({ data: result, getY });

  // There is a possibility that a deleted cluster generated a cost, and a new cluster with the same name has been deployed
  // This resolves duplicated names issue adding last 5 chars from resource uid to the name
  const uniqueSortedItems = sortedItems.map((item, _, self) => {
    if (
      self.filter((selfItem) => selfItem.entity.name === item.entity.name)
        .length > 1
    ) {
      return {
        ...item,
        entity: {
          ...item.entity,
          name: `${item.entity.name} (${item.entity.uid.slice(-5)})`,
        },
      };
    }

    return item;
  });

  const slicedItems = uniqueSortedItems.slice(0, threshold);

  const coordinates = slicedItems.map((resource) => {
    if (!resource?.data?.length) {
      return {
        id: resource.entity.name,
        data: [],
      };
    }

    return {
      id: resource.entity.name,
      data: getChartDataCoordinates({
        data: resource.data,
        getY,
        xFormat,
      }),
    };
  });

  if (sortedItems.length > threshold) {
    const othersResources = sortedItems.slice(threshold, sortedItems.length);
    const othersValue = cumulateOtherCosts({
      items: othersResources,
      getY,
      xFormat,
    });
    const othersDetails = othersResources.map((resource) => ({
      name: resource.entity.name,
    }));

    coordinates.push({
      id: `others (${othersResources.length} ${
        othersResources.length > 1 ? "items" : "item"
      })`,
      data: othersValue,
      extendedDetails: othersDetails,
    });
  }

  const hasTenantLevelClustersProject = coordinates?.some(
    (coord) => coord?.id === TENANT_LEVEL_CLUSTERS_PROJECT.name
  );

  return [...coordinates]
    .reverse()
    .sort((a, b) => b.data.length - a.data.length)
    .map((item, index) => {
      const colorIndex = hasTenantLevelClustersProject
        ? coordinates.length - 1 - index - 1
        : coordinates.length - 1 - index;
      const color =
        item?.id === TENANT_LEVEL_CLUSTERS_PROJECT.name
          ? lightMidGray
          : COST_CHART_COLORS[colorIndex];

      return {
        ...item,
        color,
      };
    });
}

export function parseAppliances(items) {
  return items
    .map((item) => {
      let label = item.metadata.name;
      if (item.metadata?.labels?.name) {
        label = `${item.metadata.labels.name} (${item.metadata.name})`;
      }

      const appliance = {
        ...item,
        metadata: { ...item.metadata, name: label },
        spec: {
          ...item.spec,
          clusterProfileTemplates: (
            item.spec?.clusterProfileTemplates || []
          ).map((template) => {
            return {
              metadata: { uid: template?.uid || template?.name },
              spec: { published: { ...template } },
            };
          }),
        },
      };

      return appliance;
    })
    .sort((machineA, machineB) =>
      machineA.metadata.name.localeCompare(machineB.metadata.name)
    );
}

export function parseTotalsByMarkerState(items) {
  const data = (items || []).reduce((acc, item) => {
    const state = item?.markerState;
    if (state) {
      acc[state] = (acc[state] || 0) + 1;
    }
    return acc;
  }, {});
  data.total = items?.length || 0;
  return data;
}

export function parseFilterGroup(item) {
  const { property, values, operator, type } = item;

  if (type === "bool") {
    return {
      property,
      type,
      condition: {
        [type]: {
          value: getBoolean(values),
        },
      },
    };
  }

  return {
    property: property,
    type: type,
    condition: {
      [type]: {
        operator: OPERATORS_MAPPING[operator].value,
        negation: OPERATORS_MAPPING[operator].negation,
        match: {
          conjunction: "or",
          values: Array.isArray(values) ? values : [values],
        },
        ignoreCase: false,
      },
    },
  };
}

export function parseChildManifests(manifests) {
  const data = (manifests || []).map(({ name, values }) => ({
    name,
    content: values || "",
  }));
  return data;
}

export function parseFilterTag(condition) {
  const operator =
    ALL_OPERATORS.find((item) => item.value === condition?.operator)?.label ||
    "is";

  if (condition.type === "bool") {
    const boolOperator = condition.value || condition.values ? "is" : "is not";
    return `${boolOperator} ${condition.displayName}`;
  }

  return `${condition.displayName} ${operator} ${
    condition.value || condition.values
  }`;
}
