import React from "react";
import { createSelector } from "reselect";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import Fields from "components/ui/Fields";
import { FieldWrap } from "components/styled/Field";
import { getStoreEntity } from "services/store";

const RefreshIcon = styled(FontAwesomeIcon)`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin: 55px 0 0 10px;
`;

export const FetcherFieldWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: -20px 0;

  & + &,
  ${FieldWrap} + & {
    margin-top: -20px;
    margin-bottom: -20px;
  }

  > ${FieldWrap} {
    max-width: ${(props) => props.allowRefresh && "430px"};
  }
`;

const SEPARATOR = "~";

export function useDataFetcher(fetcher) {
  return useSelector(fetcher.selector);
}

export default function dataFetcher({
  selectors = [],
  schema,
  fetchData,
  keepStale = false,
}) {
  function getPath(state) {
    return selectors.reduce((accumulator, selector) => {
      function join(string) {
        return accumulator ? `${accumulator}${SEPARATOR}${string}` : string;
      }
      if (typeof selector === "string") {
        return join(selector);
      }

      if (typeof selector !== "function") {
        return join("undefined");
      }

      const result = selector(state);
      return join(result);
    }, "");
  }

  function getSelector() {
    const getModuleState = createSelector(
      (state) => state.fetcher,
      getPath,
      (data, path) => {
        return (
          data[path] || {
            result: null,
            isLoading: undefined,
          }
        );
      }
    );
    if (!schema) {
      return getModuleState;
    }

    return createSelector(
      getModuleState,
      (state) => state.entities,
      (moduleState) => ({
        ...moduleState,
        result: moduleState.normalized
          ? getStoreEntity(moduleState.normalized, schema)
          : moduleState.result,
      })
    );
  }

  return {
    fetch(...args) {
      return function thunk(dispatch, getState) {
        const path = getPath(getState());
        const promise = fetchData(path.split(SEPARATOR), ...args);
        dispatch({
          type: "FETCH_DATA",
          path,
          promise,
          schema,
          keepStale,
        });

        return promise;
      };
    },

    selector: getSelector(),

    flushCache() {
      return (dispatch, getState) => {
        const path = getPath(getState());
        dispatch({
          type: "FLUSH_DATA",
          path,
        });
      };
    },
  };
}

export function keyedDataFetcher({ selectors, fetchData }) {
  const _registry = {};
  return {
    key(key) {
      if (_registry[key]) {
        return _registry[key];
      }

      const fetcher = dataFetcher({
        selectors: [...selectors, key],
        fetchData,
      });
      _registry[key] = fetcher;

      return _registry[key];
    },

    selector(state) {
      return Object.keys(_registry).reduce((accumulator, key) => {
        accumulator[key] = _registry[key].selector(state);
        return accumulator;
      }, {});
    },
  };
}

export function mapMetadataAsOptions(items) {
  return (items || []).map((item) => ({
    label: item.metadata.name,
    value: item.metadata.uid,
  }));
}

export function mapStringsAsOptions(items) {
  return (items || []).map((item) => ({
    label: item,
    value: item,
  }));
}

export function createSelectComponent({
  Component = Fields.Select,
  dataFetcher,
  parseOptions = (result) => result,
}) {
  const getOptions = (fetchStatus, identifier) => {
    const isLoading = identifier
      ? fetchStatus[identifier]?.isLoading
      : fetchStatus.isLoading;

    if (isLoading) {
      return [];
    }
    return identifier
      ? parseOptions(fetchStatus[identifier]?.result, identifier)
      : parseOptions(fetchStatus.result);
  };

  const createFetcherSelector = (identifier) =>
    createSelector(dataFetcher.selector, (fetchStatus) => {
      return {
        options: getOptions(fetchStatus, identifier),
        loading: identifier
          ? fetchStatus[identifier]?.isLoading
          : fetchStatus.isLoading,
      };
    });

  const ComponentWrapper = (props) => {
    const { disabled, allowRefresh, onRefresh, componentRef } = props;

    return (
      <FetcherFieldWrapper allowRefresh={allowRefresh}>
        <Component ref={componentRef} {...props} />
        {allowRefresh && (
          <RefreshIcon
            disabled={disabled}
            icon={faSync}
            onClick={!disabled && onRefresh}
          />
        )}
      </FetcherFieldWrapper>
    );
  };

  return connect(
    (state, ownProps) => createFetcherSelector(ownProps.identifier)(state),
    (dispatch, ownProps) => ({
      onRefresh: () =>
        dispatch(
          ownProps.identifier
            ? dataFetcher.key(ownProps.identifier).fetch()
            : dataFetcher.fetch()
        ),
    })
  )(ComponentWrapper);
}

export function isDataLoading(...fetchers) {
  return createSelector(
    fetchers.map((fetcher) => fetcher.selector),
    (...args) => {
      return args.some((fetcherState) => {
        if (typeof fetcherState.isLoading === "undefined") {
          return true;
        }
        return fetcherState.isLoading;
      });
    }
  );
}
