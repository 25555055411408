import store from "services/store";
import i18n from "i18next";
import api from "services/api";
import { generatePath } from "react-router";

import historyService from "services/history";
import notifications from "services/notifications";
import Validator from "services/validator";
import { Missing, isValidUrl, ApplyIf } from "services/validator/rules";

import createActions from "modules/form/actions";
import {
  packRegistriesActions,
  helmRegistriesActions,
  ociRegistriesActions,
} from "./list";
import {
  getSelectedPackRegistry,
  getSelectedHelmRegistry,
  getSelectedOCIRegistry,
} from "state/packregistries/selectors";

import {
  packRegistriesFetcher,
  ociRepositoriesFetcher,
  helmRegistriesFetcher,
  packRegistryModal,
  helmRegistryModal,
  ociRegistryModal,
  PACK_MODULE,
  HELM_MODULE,
  OCI_MODULE,
} from "state/packregistries/services";
import { REGISTRIES, SETTINGS } from "utils/constants/routes";

export const ENDPOINT_TEMPLATE_STRING = "{{.spectro.system.aws.region}}";

export const validator = new Validator();
validator.addRule(["name", "endpoint"], Missing());
validator.addRule(["endpoint"], isValidUrl());
validator.addRule(["username", "password"], (value, key, data) => {
  if (data.noAuth) {
    return false;
  }
  return Missing()(value, key, data);
});

export function showDefaultRegion({ authType, endpoint } = {}) {
  return authType === "ecr" && endpoint.includes(ENDPOINT_TEMPLATE_STRING);
}

function getSubmitPayload(
  {
    name,
    endpoint,
    noAuth,
    username,
    password,
    authType,
    credentialType,
    isPrivate,
    defaultRegion,
    providerType,
    ...rest
  },
  isDevMode = false
) {
  const base = {
    metadata: {
      name,
    },
    spec: {
      name,
      endpoint,
      isPrivate,
      providerType,
      defaultRegion: showDefaultRegion({ authType, endpoint })
        ? defaultRegion
        : undefined,
      ...(isDevMode && { scope: "app" }),
    },
  };

  let auth = {
    type: noAuth ? "noAuth" : "basic",
    username: noAuth ? undefined : username,
    password: noAuth ? undefined : password,
  };

  if (providerType === "helm" && authType !== "basic") {
    if (credentialType === "secret") {
      auth = {
        credentialType: "secret",
        accessKey: rest.accessKey,
        secretKey: rest.secretKey,
      };
    } else {
      auth = {
        credentialType: "sts",
        sts: {
          arn: rest.arn,
          externalId: rest.externalId,
        },
      };
    }
    if (!isPrivate) {
      auth = undefined;
    }
  }

  if (providerType === "zarf") {
    auth = {
      type: noAuth ? "noAuth" : "basic",
      username: noAuth ? undefined : username,
      password: noAuth ? undefined : password,
    };
  }

  return {
    ...base,
    spec: {
      ...base.spec,
      ...(authType === "basic" ? { auth } : { credentials: auth }),
    },
  };
}

function getValidationPayload(formData) {
  return {
    endpoint: formData.endpoint,
    auth: {
      username: formData.username,
      password: formData.password,
      type: "basic",
    },
  };
}

async function submit(data) {
  const selectedPackRegistry = getSelectedPackRegistry(store.getState());
  const payload = getSubmitPayload(data);
  let response;
  try {
    response = selectedPackRegistry
      ? await api.put(
          `v1/registries/pack/${selectedPackRegistry.metadata.uid}`,
          payload
        )
      : await api.post("v1/registries/pack", payload);
  } catch (error) {
    const message = selectedPackRegistry
      ? i18n.t("Something went wrong when editing a pack registry")
      : i18n.t("Something went wrong when creating a pack registry");

    notifications.error({
      message,
      description: error.message,
    });
  }

  if (selectedPackRegistry) {
    store.dispatch(packRegistriesActions.initialize(PACK_MODULE));
    historyService.push("/settings/registries/pack");
    return;
  }

  if (!response) {
    return;
  }

  historyService.push("/settings/registries/pack");
  store.dispatch(
    packRegistriesActions.addItems({
      module: PACK_MODULE,
      items: [
        {
          name: data.name,
          endpoint: data.endpoint,
          uid: response.uid,
        },
      ],
    })
  );
}

async function init() {
  let data;
  if (packRegistryModal?.data.uid) {
    data = await store.dispatch(packRegistriesFetcher.fetch());
  }

  return {
    authType: "basic",
    name: data?.metadata?.name || "",
    endpoint: data?.spec?.endpoint || "",
    username: data?.spec?.auth?.username || "",
    password: data?.spec?.auth?.password || "",
    isValid: !!packRegistryModal?.data?.uid,
  };
}

export function openPackRegistryModal(uid) {
  return (dispatch) => {
    packRegistryModal.open({ uid }).then(
      () => {
        return dispatch(
          packRegistryFormActions.submit({ module: PACK_MODULE })
        );
      },
      () => historyService.push("/settings/registries/pack")
    );
    dispatch(packRegistryFormActions.init({ module: PACK_MODULE }));
  };
}

export const packRegistryFormActions = createActions({
  init,
  submit,
  validator,
});

export function validatePackRegistry() {
  return async (dispatch, getState) => {
    const formData = getState().forms?.packregistries?.data;
    if (!formData) {
      return;
    }

    const errors = await dispatch(
      packRegistryFormActions.validateForm({
        module: PACK_MODULE,
      })
    );

    if (errors.length > 0) {
      return;
    }

    const payload = getValidationPayload(formData);
    const promise = api.post("v1/registries/pack/validate", payload);

    dispatch({
      type: "VALIDATE_REGISTRY",
      formData,
      promise,
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong. We couldn't validate your pack registry details."
        ),
        description: err.message,
      });
    }
  };
}

export function onResetField(name, value) {
  return (dispatch, getState) => {
    const props = { name, value, module: PACK_MODULE };
    const isPackRegistryValidated = getState().registry.isValid;

    if (isPackRegistryValidated) {
      dispatch({
        type: "SET_INVALID_REGISTRY",
      });
    }

    dispatch(packRegistryFormActions.onChange(props));
  };
}

export const helmRegistryFormActions = createActions({
  init: async () => {
    let data;
    if (helmRegistryModal?.data?.uid) {
      data = await store.dispatch(helmRegistriesFetcher.fetch());
    }
    return Promise.resolve({
      authType: "basic",
      name: data?.metadata?.name || "",
      endpoint: data?.spec?.endpoint || "",
      username: data?.spec?.auth?.username || "",
      password: data?.spec?.auth?.password || "",
      isPrivate: data?.spec?.isPrivate || false,
      isValid: !!helmRegistryModal?.data?.uid,
      noAuth: helmRegistryModal?.data?.uid
        ? data?.spec?.auth?.type === "noAuth"
        : true,
      persisted: !!data,
    });
  },
  submit: async (data) => {
    const selectedRegistry = getSelectedHelmRegistry(store.getState());
    const isDevMode = store.getState().auth.devMode;
    const returnPath = isDevMode
      ? generatePath(REGISTRIES.ROOT, { tab: "helm" })
      : generatePath(SETTINGS.REGISTRIES, { tab: "helm" });
    const payload = getSubmitPayload(data, isDevMode);

    let response;
    try {
      response = selectedRegistry
        ? await api.put(
            `v1/registries/helm/${selectedRegistry.metadata.uid}`,
            payload
          )
        : await api.post("v1/registries/helm", payload);
    } catch (error) {
      const message = selectedRegistry
        ? i18n.t("Something went wrong when editing a helm registry")
        : i18n.t("Something went wrong when creating a helm registry");

      notifications.error({
        message,
        description: error.message,
      });
    }

    if (selectedRegistry) {
      store.dispatch(helmRegistriesActions.initialize(HELM_MODULE));
      historyService.push(returnPath);
      return;
    }

    if (!response) {
      return;
    }

    historyService.push(returnPath);
    store.dispatch(
      helmRegistriesActions.addItems({
        module: HELM_MODULE,
        items: [
          {
            name: data.name,
            endpoint: data.endpoint,
            uid: response.uid,
          },
        ],
      })
    );
  },
  validator,
});

export function openHelmRegistryModal(uid) {
  return (dispatch, getState) => {
    const isDevMode = getState().auth.devMode;
    const returnPath = isDevMode
      ? generatePath(REGISTRIES.ROOT, { tab: "helm" })
      : generatePath(SETTINGS.REGISTRIES, { tab: "helm" });

    helmRegistryModal.open({ uid }).then(
      () => {
        return dispatch(
          helmRegistryFormActions.submit({ module: HELM_MODULE })
        );
      },
      () => historyService.push(returnPath)
    );
    dispatch(helmRegistryFormActions.init({ module: HELM_MODULE }));
  };
}

export function validateHelmRegistry() {
  return async (dispatch, getState) => {
    const formData = getState().forms?.helmregistries?.data;
    if (!formData) {
      return;
    }

    const errors = await dispatch(
      helmRegistryFormActions.validateForm({
        module: HELM_MODULE,
      })
    );

    if (errors.length > 0) {
      return;
    }

    const payload = getValidationPayload(formData);
    const promise = api.post("v1/registries/helm/validate", payload);

    dispatch({
      type: "VALIDATE_REGISTRY",
      formData,
      promise,
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong. We couldn't validate your helm registry details."
        ),
        description: err.message,
      });
    }
  };
}

export function onHelmChangeField(name, value) {
  return (dispatch, getState) => {
    const props = { name, value, module: HELM_MODULE };
    const formData = getState().forms?.helmregistries?.data;

    let invalidate = getState().registry.isValid;
    if (formData.isPrivate) {
      invalidate = false;
    }

    if (invalidate) {
      dispatch({
        type: "SET_INVALID_REGISTRY",
      });
    }

    if (name === "isPrivate") {
      if (value) {
        dispatch({
          type: "VALIDATE_REGISTRY_SUCCESS",
        });
      } else {
        dispatch({
          type: "SET_INVALID_REGISTRY",
        });
      }
    }

    dispatch(helmRegistryFormActions.onChange(props));
  };
}

export const ociValidator = new Validator();
ociValidator.addRule(["name", "endpoint"], Missing());
ociValidator.addRule(
  ["username", "password"],
  ApplyIf(
    (value, key, data) =>
      (data.providerType === "helm" && data.authType === "basic") ||
      (data.providerType === "zarf" && !data.noAuth),
    Missing()
  )
);

ociValidator.addRule(
  ["arn", "externalId"],
  ApplyIf(
    (value, key, data) =>
      data.authType === "ecr" &&
      data.isPrivate &&
      data.credentialType === "sts",
    Missing()
  )
);

ociValidator.addRule(
  ["accessKey", "secretKey"],
  ApplyIf(
    (value, key, data) =>
      data.authType === "ecr" &&
      data.isPrivate &&
      data.credentialType === "secret",
    Missing()
  )
);

ociValidator.addRule(
  ["defaultRegion"],
  ApplyIf((value, key, data) => showDefaultRegion(data), Missing())
);

export const ociRegistryFormActions = createActions({
  init: async () => {
    let data;
    if (ociRegistryModal?.data?.uid) {
      data = await store.dispatch(ociRepositoriesFetcher.fetch());
    }

    const stsData = await api.get("v1/clouds/aws/account/sts");

    return Promise.resolve({
      name: data?.metadata?.name || "",
      endpoint: data?.spec?.endpoint || "",
      username: data?.spec?.auth?.username || "",
      password: data?.spec?.auth?.password || "",
      noAuth: data?.spec?.auth?.type === "noAuth",
      isValid: !!ociRegistryModal?.data?.uid,
      persisted: !!ociRegistryModal?.data?.uid,
      authType: data?.type || "basic",
      defaultRegion: data?.spec?.defaultRegion || "",
      providerType: data?.spec?.providerType || "helm",
      credentialType: data?.spec?.credentials?.credentialType || "secret",
      isPrivate: data?.spec?.isPrivate || false,
      arn: data?.spec?.credentials?.sts?.arn || "",
      externalId: stsData.externalId,
      accountId: stsData.accountId,
      accessKey: data?.spec?.credentials?.accessKey || "",
      secretKey: data?.spec?.credentials?.secretKey || "",
    });
  },
  submit: async (data) => {
    const selectedRegistry = getSelectedOCIRegistry(store.getState());
    const isDevMode = store.getState().auth.devMode;
    const returnPath = isDevMode
      ? generatePath(REGISTRIES.ROOT, { tab: "oci" })
      : generatePath(SETTINGS.REGISTRIES, { tab: "oci" });
    const payload = getSubmitPayload(data, isDevMode);

    let response;
    try {
      response = selectedRegistry
        ? await api.put(
            `v1/registries/oci/${selectedRegistry.metadata.uid}/${data.authType}`,
            payload
          )
        : await api.post(`v1/registries/oci/${data.authType}`, payload);
    } catch (error) {
      const message = selectedRegistry
        ? i18n.t("Something went wrong when editing the OCI registry")
        : i18n.t("Something went wrong when creating the OCI registry");

      notifications.error({
        message,
        description: error.message,
      });
    }

    if (selectedRegistry) {
      store.dispatch(ociRegistriesActions.initialize(OCI_MODULE));
      historyService.push(returnPath);
      return;
    }

    if (!response) {
      return;
    }

    historyService.push(returnPath);
    store.dispatch(
      helmRegistriesActions.addItems({
        module: OCI_MODULE,
        items: [
          {
            name: data.name,
            endpoint: data.endpoint,
            uid: response.uid,
          },
        ],
      })
    );
  },
  validator: ociValidator,
});

export function validateOciRegistry() {
  return async (dispatch, getState) => {
    const formData = getState().forms?.ociregistries?.data;
    if (!formData) {
      return;
    }

    const errors = await dispatch(
      ociRegistryFormActions.validateForm({
        module: OCI_MODULE,
      })
    );

    if (errors.length > 0) {
      return;
    }

    const payload = getValidationPayload(formData);
    const promise = api.post("v1/registries/oci/validate", payload);

    dispatch({
      type: "VALIDATE_REGISTRY",
      formData,
      promise,
    });

    try {
      await promise;
    } catch (err) {
      notifications.error({
        message: i18n.t(
          "Something went wrong. We couldn't validate your helm registry details."
        ),
        description: err.message,
      });
    }
  };
}

export function onOciChangeField(name, value) {
  return (dispatch, getState) => {
    const props = { name, value, module: OCI_MODULE };
    const isRegistryValidated = getState().registry.isValid;

    if (isRegistryValidated && ["sts"].includes(name)) {
      dispatch({
        type: "SET_INVALID_REGISTRY",
      });
    }

    dispatch(ociRegistryFormActions.onChange(props));

    if (name === "providerType" && value === "zarf") {
      dispatch(
        ociRegistryFormActions.batchChange({
          updates: {
            authType: "basic",
            noAuth: true,
          },
          module: OCI_MODULE,
        })
      );
    }
  };
}

export function openOciRegistryModal(uid, ociType) {
  return (dispatch, getState) => {
    const isDevMode = getState().auth.devMode;
    const returnPath = isDevMode
      ? generatePath(REGISTRIES.ROOT, { tab: "oci" })
      : generatePath(SETTINGS.REGISTRIES, { tab: "oci" });

    ociRegistryModal.open({ uid, ociType }).then(
      () => {
        return dispatch(ociRegistryFormActions.submit({ module: OCI_MODULE }));
      },
      () => historyService.push(returnPath)
    );
    dispatch(ociRegistryFormActions.init({ module: OCI_MODULE }));
  };
}
