import store from "services/store";
import withPolling from "utils/withPolling";
import { getCluster, isBrownfield } from "state/cluster/selectors/details";
import {
  fetchClusterStatus,
  fetchClusterNotifications,
  fetchLogStatus,
} from "state/cluster/actions/details";
import { fetchClusterProfileNotifications } from "state/clusterprofile/actions/details";
import { getNodeStatus, getNodePools } from "state/cluster/selectors/nodes";
import { fetchClusterNodes } from "state/cluster/actions/nodes";
import { overlordsListActions } from "state/overlord/actions/list";
import { OVERLORD_MODULE } from "state/overlord/services/list";
import {
  eventListActions,
  clusterErrorFetcher,
  lastClusterModificationFetcher,
} from "state/cluster/services";
import { refreshClustersList } from "state/cluster/actions/list/clusters";
import {
  backupsListActions,
  restoresListActions,
  BACKUP_LIST_MODULE,
  RESTORE_LIST_MODULE,
} from "state/backups/services";
import { refreshRegistries } from "state/packregistries/actions/list";

import { fetchNamespacesConsumption as clusterNsConsumption } from "state/cluster/actions/workloads";
import { fetchNamespacesConsumption as workspaceNsConsumption } from "state/workspaces/actions/details";

import { MINUTE_IN_MILLISECONDS } from "./date";
import { workspacesWorkloadsModules as workspaceWorkloadsActions } from "state/workspaces/services/workloads";
import { workloadsModules as clusterWorkloadsActions } from "state/cluster/actions/workloads";
import { edgeMachinesListActions } from "state/cluster/actions/list/edgemachines";
import {
  APPLIANCE_EVENTS_LIST_MODULE,
  EDGE_MACHINES_MODULE,
  applianceEventsListActions,
} from "state/cluster/services/edgemachines";

import {
  workspaceBackupsListActions,
  workspaceRestoresListActions,
  WORKSPACE_BACKUP_LIST_MODULE,
  WORKSPACE_RESTORE_LIST_MODULE,
} from "state/workspaces/services/backups";

import {
  workspaceBackupsListActions as clusterGroupBackupsListActions,
  workspaceRestoresListActions as clusterGroupRestoresListActions,
  WORKSPACE_BACKUP_LIST_MODULE as CLUSTER_GROUP_BACKUP_LIST_MODULE,
  WORKSPACE_RESTORE_LIST_MODULE as CLUSTER_GROUP_RESTORE_LIST_MODULE,
} from "state/clustergroups/services/backups";
import { clusterGroupsWorkloadsModules } from "state/clustergroups/services/workloads";
import { fetchNamespacesConsumption as clusterGroupNsConsumption } from "state/clustergroups/actions/details";

import {
  virtualClustersListActions,
  VIRTUAL_CLUSTERS_LIST_MODULE,
} from "state/cluster/actions/list/nestedclusters";
import {
  appDeploymentsListActions,
  DEPLOYMENTS_LIST_MODULE,
} from "state/appdeployments/services/listings";
import {
  applicationNotificationsFetcher,
  refreshAppDeployment,
} from "state/appdeployments/services/details";
import { fetchAppLogStatus } from "state/appdeployments/actions/details";

const getActivatedCluster = async () => {
  let state = store.getState();
  let cluster = getCluster(state);
  await store.dispatch(fetchClusterStatus(cluster.metadata.uid));

  return Promise.reject();
};

export const pollClusterStatus = withPolling(getActivatedCluster, 5000);

const getActiveNodes = async () => {
  const nodePoolsNodes = await store.dispatch(fetchClusterNodes());
  let state = store.getState();
  const isClusterBrownField = isBrownfield(state);
  const clusterNodes = nodePoolsNodes.reduce(
    (accumulator, { items }) => [...accumulator, ...items],
    []
  );

  const activeNodes = clusterNodes.filter((node) => {
    const nodeStatus = getNodeStatus(node);
    return nodeStatus === "running";
  });

  const nodePoolsSize = getNodePools(store.getState()).reduce(
    (accumulator, nodePool) => accumulator + nodePool.size,
    0
  );

  const nodes = getNodePools(store.getState()).reduce(
    (accumulator, nodePool) => accumulator + nodePool.nodes.length,
    0
  );

  let shouldStop = nodes === activeNodes.length && nodes === nodePoolsSize;
  if (isClusterBrownField) {
    shouldStop = nodes && shouldStop;
  }

  if (!shouldStop) {
    throw new Error("Not all nodes are active");
  }

  return Promise.resolve();
};

export const pollNodes = withPolling(getActiveNodes, 5000);

const getClusterEvents = () => {
  const listState = store.getState().list.clusterevents;
  if (listState.nextToken === undefined) {
    store.dispatch(eventListActions.fetchItems("clusterevents"));
  }
  store.dispatch(eventListActions.nextPage("clusterevents"));

  return Promise.reject();
};

export const pollClusterEvents = withPolling(getClusterEvents, 7000);

const getDatacenters = () => {
  store.dispatch(overlordsListActions.refreshItems(OVERLORD_MODULE));

  return Promise.reject();
};

export const pollDatacenters = withPolling(getDatacenters, 5000);
export const pollClusterNotifications = withPolling(() => {
  store.dispatch(fetchClusterNotifications());
  return Promise.reject();
}, 5000);

export const pollClusterProfileNotifications = withPolling(() => {
  store.dispatch(fetchClusterProfileNotifications());
  return Promise.reject();
}, 5000);

export const poolClusterErrors = withPolling(() => {
  store.dispatch(
    clusterErrorFetcher.fetch({
      interval: "5 minute",
      status: ["Error"],
    })
  );

  return Promise.reject();
}, 20000);

export const pollSpectroClusters = withPolling(() => {
  store.dispatch(refreshClustersList());
  return Promise.reject();
}, 20000);

const getApplianceEvents = () => {
  const listState = store.getState().list?.[APPLIANCE_EVENTS_LIST_MODULE];
  if (listState.nextToken === undefined) {
    store.dispatch(
      applianceEventsListActions.fetchItems(APPLIANCE_EVENTS_LIST_MODULE)
    );
  }
  store.dispatch(
    applianceEventsListActions.nextPage(APPLIANCE_EVENTS_LIST_MODULE)
  );

  return Promise.reject();
};

export const pollApplianceEvents = withPolling(getApplianceEvents, 10000);

export const pollAppliances = withPolling(() => {
  store.dispatch(edgeMachinesListActions.refreshItems(EDGE_MACHINES_MODULE));
  return Promise.reject();
}, 10000);

export const pollClusterLogStatus = withPolling(
  () => store.dispatch(fetchLogStatus()),
  5000
);

export const pollAppLogStatus = withPolling(
  () => store.dispatch(fetchAppLogStatus()),
  5000
);

export const pollBackupList = withPolling(async () => {
  store.dispatch(backupsListActions.fetchItems(BACKUP_LIST_MODULE));
  return Promise.reject();
}, 10000);

export const pollRestoreList = withPolling(async () => {
  store.dispatch(restoresListActions.fetchItems(RESTORE_LIST_MODULE));
  return Promise.reject();
}, 10000);

export const pollLastClusterModification = withPolling(async () => {
  store.dispatch(lastClusterModificationFetcher.fetch());
  return Promise.reject();
}, 20000);

export const pollRegistries = withPolling(() => {
  store.dispatch(refreshRegistries());
  return Promise.reject();
});

export const pollVirtualClusters = withPolling(() => {
  store.dispatch(
    virtualClustersListActions.refreshItems(VIRTUAL_CLUSTERS_LIST_MODULE)
  );
  return Promise.reject();
}, 20000);

// Workspace Workloads Tasks

export const pollWorkspacesNamespacesConsumption = withPolling(() => {
  store.dispatch(workspaceNsConsumption());
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 5);

export const pollWorkspacesNamespaces = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.namespaces;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesPods = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.pods;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesDeployments = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.deployments;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesDaemonsets = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.daemonsets;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesStatefulsets = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.statefulsets;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesJobs = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.jobs;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesCronjobs = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.cronjobs;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesBackupList = withPolling(async () => {
  store.dispatch(
    workspaceBackupsListActions.fetchItems(WORKSPACE_BACKUP_LIST_MODULE)
  );
  return Promise.reject();
}, 10000);

export const pollWorkspacesRestoreList = withPolling(async () => {
  store.dispatch(
    workspaceRestoresListActions.fetchItems(WORKSPACE_RESTORE_LIST_MODULE)
  );
  return Promise.reject();
}, 10000);

export const pollClusterGroupsBackupList = withPolling(async () => {
  store.dispatch(
    clusterGroupBackupsListActions.fetchItems(CLUSTER_GROUP_BACKUP_LIST_MODULE)
  );
  return Promise.reject();
}, 10000);

export const pollClusterGroupsRestoreList = withPolling(async () => {
  store.dispatch(
    clusterGroupRestoresListActions.fetchItems(
      CLUSTER_GROUP_RESTORE_LIST_MODULE
    )
  );
  return Promise.reject();
}, 10000);

export const pollWorkspacesRoleBindings = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.rolebindings;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollWorkspacesClusterRoleBindings = withPolling(() => {
  const { listActions, module } = workspaceWorkloadsActions.clusterrolebindings;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const workspaceWorkloadPollersByTab = {
  namespaces: [pollWorkspacesNamespaces, pollWorkspacesNamespacesConsumption],
  pods: [pollWorkspacesPods],
  deployments: [pollWorkspacesDeployments],
  daemonsets: [pollWorkspacesDaemonsets],
  statefulsets: [pollWorkspacesStatefulsets],
  jobs: [pollWorkspacesJobs],
  cronjobs: [pollWorkspacesCronjobs],
  rolebindings: [pollWorkspacesRoleBindings],
  clusterrolebindings: [pollWorkspacesClusterRoleBindings],
};

// Cluster Workloads Tasks

export const pollClusterNamespacesConsumption = withPolling(() => {
  store.dispatch(clusterNsConsumption());
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 5);

export const pollClusterNamespaces = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.namespaces;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterPods = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.pods;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterDeployments = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.deployments;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterDaemonsets = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.daemonsets;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterStatefulsets = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.statefulsets;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterJobs = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.jobs;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterCronjobs = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.cronjobs;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterRoleBindings = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.rolebindings;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterRoles = withPolling(() => {
  const { listActions, name } = clusterWorkloadsActions.clusterrolebindings;
  store.dispatch(listActions.refreshItems(name));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const clusterWorkloadPollersByTab = {
  namespaces: [pollClusterNamespaces, pollClusterNamespacesConsumption],
  pods: [pollClusterPods],
  deployments: [pollClusterDeployments],
  daemonsets: [pollClusterDaemonsets],
  statefulsets: [pollClusterStatefulsets],
  jobs: [pollClusterJobs],
  cronjobs: [pollClusterCronjobs],
  rolebindings: [pollClusterRoleBindings],
  clusterrolebindings: [pollClusterRoles],
};

// Cluster Group Workloads Tasks

export const pollClusterGroupNamespacesConsumption = withPolling(() => {
  store.dispatch(clusterGroupNsConsumption());
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 5);

export const pollClusterGroupNamespaces = withPolling(() => {
  const { listActions, module } = clusterGroupsWorkloadsModules.namespaces;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterGroupPods = withPolling(() => {
  const { listActions, module } = clusterGroupsWorkloadsModules.pods;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterGroupDeployments = withPolling(() => {
  const { listActions, module } = clusterGroupsWorkloadsModules.deployments;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterGroupDaemonsets = withPolling(() => {
  const { listActions, module } = clusterGroupsWorkloadsModules.daemonsets;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterGroupStatefulsets = withPolling(() => {
  const { listActions, module } = clusterGroupsWorkloadsModules.statefulsets;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterGroupJobs = withPolling(() => {
  const { listActions, module } = clusterGroupsWorkloadsModules.jobs;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterGroupCronjobs = withPolling(() => {
  const { listActions, module } = clusterGroupsWorkloadsModules.cronjobs;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterGroupRoleBindings = withPolling(() => {
  const { listActions, module } = clusterGroupsWorkloadsModules.rolebindings;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const pollClusterGroupClusterRoleBindings = withPolling(() => {
  const { listActions, module } =
    clusterGroupsWorkloadsModules.clusterrolebindings;
  store.dispatch(listActions.refreshItems(module));
  return Promise.reject();
}, MINUTE_IN_MILLISECONDS * 1);

export const clusterGroupWorkloadPollersByTab = {
  namespaces: [
    pollClusterGroupNamespaces,
    pollClusterGroupNamespacesConsumption,
  ],
  pods: [pollClusterGroupPods],
  deployments: [pollClusterGroupDeployments],
  daemonsets: [pollClusterGroupDaemonsets],
  statefulsets: [pollClusterGroupStatefulsets],
  jobs: [pollClusterGroupJobs],
  cronjobs: [pollClusterGroupCronjobs],
  rolebindings: [pollClusterGroupRoleBindings],
  clusterrolebindings: [pollClusterGroupClusterRoleBindings],
};

// devx Apps tasks

export const pollAppDeployments = withPolling(() => {
  store.dispatch(
    appDeploymentsListActions.refreshItems(DEPLOYMENTS_LIST_MODULE)
  );
  return Promise.reject();
}, 10000);

export const pollAppDeploymentDetails = withPolling(() => {
  store.dispatch(refreshAppDeployment());
  return Promise.reject();
}, 10000);

export const pollAppDeploymentNotifications = withPolling(() => {
  store.dispatch(applicationNotificationsFetcher.fetch());
  return Promise.reject();
}, 10000);
