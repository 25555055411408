import { createSelector } from "reselect";
import fetchers, { MODULES } from "./services";

function findRepositoryByUid(repos, repoUid) {
  return repos.find((repo) => repo.metadata.uid === repoUid);
}

export const getAllRepos = createSelector(
  fetchers.allRepositoriesFetcher.selector,
  fetchers.ociRepositoriesFetcher.selector,
  ({ result: allRepos }, { result: ociRepos }) => {
    return (allRepos || []).map((repo) => {
      const ociRepo = (ociRepos || []).find(
        (ociRepo) => repo.uid === ociRepo.metadata.uid
      );

      if (ociRepo?.spec?.providerType === "zarf") {
        return {
          ...repo,
          kind: "zarf",
        };
      }

      return repo;
    });
  }
);

export const getAllReposByPackType = createSelector(
  getAllRepos,
  (state) => state.forms.layerPack?.data?.packType,
  (repos, packType) => {
    let type = packType;

    if (!type) return [];

    let allowedTypes = ["pack"];

    if (packType === "helmChart") {
      allowedTypes = ["oci", "helm"];
    }

    if (packType === "zarf") {
      allowedTypes = ["zarf"];
    }

    return [
      ...(repos || [])
        .filter((repo) => allowedTypes.includes(repo.kind))
        .map((repoInfo) => ({
          ...repoInfo,
          metadata: { uid: repoInfo.uid, name: repoInfo.name },
        })),
    ];
  }
);

export const getAvailableRepositories = createSelector(
  getAllReposByPackType,
  (repos) => {
    return (
      repos?.map((repo) => ({
        ...repo,
        label: repo.metadata.name,
        value: repo.metadata.uid,
      })) || []
    );
  }
);

export const arePacksLoading = createSelector(
  fetchers.clusterPacksFetcher.selector,
  (state) => state.forms?.importedPacks?.data?.clusterId,
  (result, clusterId) => result[clusterId]?.isLoading
);

export const getSelectedRepository = createSelector(
  (state) => state.forms[MODULES.PACK_MODULE]?.data?.repository,
  getAvailableRepositories,
  (repoUid, repos) => findRepositoryByUid(repos, repoUid)
);

export const getAllSystemRepositories = createSelector(
  fetchers.allRepositoriesFetcher.selector,
  ({ result: repos = [] }) => {
    return [
      ...(repos || [])
        .filter((repo) => ["oci", "helm"].includes(repo.kind))
        .map((repoInfo) => ({
          ...repoInfo,
          metadata: { uid: repoInfo.uid, name: repoInfo.name },
        })),
    ].map((repo) => ({
      ...repo,
      label: repo.metadata.name,
      value: repo.metadata.uid,
    }));
  }
);

export const getSelectedSystemRepository = createSelector(
  (state) => state.forms[MODULES.SYSTEM_MODULE]?.data?.repository,
  getAllSystemRepositories,
  (repoUid, repos) => findRepositoryByUid(repos, repoUid)
);

export const getSystemSelectedPack = createSelector(
  (state) => fetchers.systemChartNamesFetcher.selector(state)?.result?.items,
  (state) => state.forms[MODULES.SYSTEM_MODULE]?.data.pack,
  (items = [], packName) => {
    return items.find((pack) => pack.spec.name === packName);
  }
);
