import { createSelector } from "reselect";
import { NodeSchema, VpcidSchema, NodePoolSchema } from "utils/schemas";
import { getEntity } from "utils/entities";
import { presentClusterProfileParams } from "utils/presenters";

import { renderInstanceTypeDescription } from "components/common/NodePoolCloudFields/InstanceTypeDescription";
import { getCluster, getClusterCloudType, getClusterProfile, hasAutoscalePack } from "./details";
import {
  azureInstanceTypesFetcher,
  azureStorageAccountsFetcher,
  azureAZFetcher,
  gcpAZFetcher,
  gcpInstanceTypesFetcher,
  nodeDetailsModalService,
  addNodePoolModal,
} from "../services/nodes";
import { datacentersFetcher } from "../services/create";
import {
  mapGcpAzs,
  mapAwsAzs,
  mapAzureAzs,
  findInstanceByType,
  getAzureCNILayer,
} from "./create";
import { edgeMachinesFetcher } from "../actions/list/edgemachines";
import { profileModule } from "../services";

export const getNode = getEntity(
  (state) => state.cluster.currentNodeOverview,
  NodeSchema
);

export const getNodePools = createSelector(getCluster, (cluster) => {
  return cluster?.spec?.machinePoolConfig || [];
});

export const getMasterNodePool = createSelector(getNodePools, (nodePools) =>
  nodePools.find((nodePool) => nodePool.isControlPlane)
);

export const getSystemNodePools = createSelector(getNodePools, (nodePools) =>
  nodePools.filter((nodePool) => nodePool?.isSystemNodePool)
);

export const getWorkerNodePools = createSelector(getNodePools, (nodePools) =>
  nodePools.filter((nodePool) => !nodePool.isControlPlane)
);

export const getAllNodes = createSelector(
  getMasterNodePool,
  getWorkerNodePools,
  (masterPool, workerPools) => {
    const pools = masterPool ? [masterPool, ...workerPools] : workerPools;
    return pools.reduce((acc, pool) => [...acc, ...pool.nodes], []);
  }
);

export const getSelectedNode = createSelector(
  getAllNodes,
  () => nodeDetailsModalService?.data?.nodeUid,
  (nodes, nodeUid) => nodes.find((node) => node.metadata.uid === nodeUid)
);

export const getNodeConditions = createSelector(getSelectedNode, (node) => {
  if (!node) {
    return [];
  }

  return node?.status?.health?.conditions?.reduce(
    (acc, condition) => [
      ...acc,
      {
        label: condition.type,
        status:
          condition.type === "Ready"
            ? condition.status === "True"
            : condition.status === "False",
      },
    ],
    []
  );
});

export const getUnhealthyNodeConditions = (conditions = []) => {
  return (conditions || []).reduce((acc, condition) => {
    const isHealthy =
      condition.type === "Ready"
        ? condition.status === "True"
        : condition.status === "False";

    if (!isHealthy) {
      return [
        ...acc,
        {
          type: condition.type,
          message: condition.message,
        },
      ];
    }
    return acc;
  }, []);
};

export const getGroupedNodePools = createSelector(
  getMasterNodePool,
  getWorkerNodePools,
  (masterPool, workerPools) =>
    workerPools.reduce(
      (acc, nodePool) => [
        ...acc,
        { header: nodePool.name, nodes: nodePool.nodes },
      ],
      masterPool ? [{ header: masterPool.name, nodes: masterPool.nodes }] : []
    )
);

export const getClusterMasterNodes = createSelector(
  getNodePools,
  getMasterNodePool,
  (nodePools, masterNodePool) => {
    if (!nodePools) {
      return [];
    }

    return masterNodePool?.nodes ? [...masterNodePool.nodes] : [];
  }
);

export const getConfiguredMasterNodes = createSelector(
  getClusterMasterNodes,
  (masterNodes) =>
    masterNodes.filter((node) => {
      const nodeStatus = getNodeStatus(node);
      return nodeStatus === "configured";
    })
);

export const getUnassignedNodes = getEntity(
  (state) => state.cluster.unassignedNodes,
  [NodeSchema]
);

export const getAddedNodes = getEntity(
  (state) => state.cluster.addedNodes,
  [NodeSchema]
);

export const getNodePoolsNodes = createSelector(getNodePools, (nodePools) =>
  nodePools.map((nodePool) => nodePool.nodes).flat()
);

export const getMasterNodesLimit = createSelector(
  getClusterMasterNodes,
  (masterNodes) => {
    const limit = [1, 3, 5];
    return limit.find((upperLimit) => masterNodes.length <= upperLimit);
  }
);

export const getClusterCloudConfig = createSelector(
  getCluster,
  (cluster) => cluster?.spec?.cloudConfig
);

function sortListOfInstanceTypes(instanceTypes) {
  return instanceTypes.sort((a, b) => (a.value > b.value ? 1 : -1));
}

function parseInstanceTypesResponse(instanceTypes, withDescription = true) {
  let groupOptionsByCategory = instanceTypes.reduce((acc, type) => {
    let category = type.category;
    if (!category) {
      category = "Others";
    }

    acc[category] = acc[category] || [];
    acc[category].push({
      title: type.type,
      value: type.type,
      description: withDescription && renderInstanceTypeDescription(type),
    });
    return acc;
  }, {});

  const othersCategory = groupOptionsByCategory["Others"];
  if (othersCategory) {
    delete groupOptionsByCategory["Others"];
    groupOptionsByCategory = {
      ...groupOptionsByCategory,
      Others: othersCategory,
    };
  }

  return Object.keys(groupOptionsByCategory).map((category) => ({
    title: category,
    value: category,
    selectable: false,
    children: sortListOfInstanceTypes(groupOptionsByCategory[category]),
  }));
}

export const getAWSInstanceTypes = createSelector(
  (state) => state.cluster?.details?.cloudConfigParams?.instanceTypes,
  (instanceTypes) => {
    if (!instanceTypes) return [];
    return parseInstanceTypesResponse(instanceTypes);
  }
);

export const getAWSAvailabilityZones = createSelector(
  (state) => state.cluster.details?.cloudConfigParams?.azs,
  (state) => state.cluster.details?.cloudConfigParams?.instanceTypes,
  (state) => state.forms.nodePool?.data?.instanceType,
  (azs, instanceTypes, selectedInstance) => {
    return mapAwsAzs(azs, instanceTypes, selectedInstance);
  }
);

export const getNodepoolClusterProfilePacks = createSelector(
  getClusterProfile,
  (clusterprofile) => {
    return presentClusterProfileParams(clusterprofile, "nodepool");
  }
);

export const getNodeStatus = (node) =>
  node?.spec?.phase || node?.status?.instanceState;

export const getVpcIds = getEntity(
  (state) => state.cluster.nodes.cloudConfigSubnets,
  [VpcidSchema]
);

export const getSubnets = createSelector(
  (state) => state.cluster.details.cloudConfigParams,
  getClusterCloudConfig,
  (cloudConfigParams, clusterCloudConfig) => {
    if (!cloudConfigParams) {
      return [];
    }

    const { vpcids } = cloudConfigParams;
    const clusterVpcId = clusterCloudConfig.spec.clusterConfig.vpcId;
    let currentAzVpc = vpcids.find((vpc) => vpc.vpcId === clusterVpcId);

    if (!currentAzVpc) {
      return;
    }

    return currentAzVpc.subnets;
  }
);

export const getSubnetsForSelectedAz = createSelector(
  getSubnets,
  (azSubnets) => {
    if (!azSubnets) {
      return;
    }

    return azSubnets.reduce((acc, value) => {
      acc[value.az] = acc[value.az] || [];
      acc[value.az].push({
        label: value.name || value.subnetId,
        value: value.subnetId,
      });
      return acc;
    }, {});
  }
);

export const getCloudConfigSubnets = createSelector(
  (state) => state.forms.nodes.data,
  (formData) => {
    return formData.azs.reduce((acc, az) => {
      const subnetIsSet = formData[`subnet_${az}`];

      if (subnetIsSet) {
        acc.push({
          az,
          id: subnetIsSet,
        });
      }
      return acc;
    }, []);
  }
);

export const isNodePoolConfigured = createSelector(
  getConfiguredMasterNodes,
  (getMasterNodes) => {
    const nodeStatus = getNodeStatus(getMasterNodes[0]);
    return nodeStatus && nodeStatus === "configured";
  }
);

export const hasAzureCNIOnEdit = createSelector(
  () =>
    profileModule?.state?.profiles?.[0]?.spec?.published?.packs.map(
      (pack) => pack.spec
    ),
  (profiles) => profiles?.find(getAzureCNILayer)
);

export const getSelectedNodePool = getEntity(
  () => addNodePoolModal.data?.nodePoolGuid,
  NodePoolSchema
);

export const getAzureInstanceTypes = (isMaster) =>
  createSelector(
    azureInstanceTypesFetcher.selector,
    azureAZFetcher.selector,
    ({ result: instanceTypes }, { result: azs }) => {
      if (!instanceTypes) {
        return [];
      }

      if (isMaster) {
        return parseInstanceTypesResponse(instanceTypes.instanceTypes);
      }

      const zoneList = azs?.zoneList || [];

      if (!zoneList.length) {
        return parseInstanceTypesResponse(instanceTypes.instanceTypes);
      }

      const filteredInstanceTypes = instanceTypes.instanceTypes.filter(
        (instance) => {
          const nonSupportedZones = instance?.nonSupportedZones || [];

          if (!nonSupportedZones.length) {
            return true;
          }

          return !zoneList.every((zone) => nonSupportedZones.includes(zone.id));
        }
      );

      return parseInstanceTypesResponse(filteredInstanceTypes);
    }
  );

export const getAzureStorageAccounts = createSelector(
  azureStorageAccountsFetcher.selector,
  ({ result }) => {
    if (!result) {
      return [];
    }

    return result.storageAccountTypes.map((accountType) => ({
      label: accountType.name,
      value: accountType.id,
    }));
  }
);

export const getAzureAzs = createSelector(
  azureAZFetcher.selector,
  azureInstanceTypesFetcher.selector,
  (state) => state.forms.nodePool?.data?.instanceType,
  ({ result: azs }, { result: instanceTypes }, selectedInstance) => {
    return mapAzureAzs(azs, instanceTypes?.instanceTypes, selectedInstance);
  }
);

export const getVsphereDomains = createSelector(
  (state) => state.cluster.create.domains,
  (domains) => {
    return domains.map((domain) => ({
      label: domain.name,
      value: domain.name,
    }));
  }
);

export const getMasterNodePoolAzs = createSelector(
  getMasterNodePool,
  (nodePool) => {
    return nodePool?.azs;
  }
);

export const getGoogleCloudAzs = createSelector(
  gcpAZFetcher.selector,
  getMasterNodePoolAzs,
  gcpInstanceTypesFetcher.selector,
  (state) => state.forms.nodePool?.data?.instanceType,
  (state) => state.forms.nodePool?.data?.isControlPlane,
  (
    { result: allAzs },
    masterNodePoolAzs,
    { result: instanceTypes },
    selectedInstance,
    isControlPlane
  ) => {
    const masterNamedAzs = masterNodePoolAzs?.map((az) => ({ name: az }));
    const azs = isControlPlane ? allAzs : masterNamedAzs;
    return mapGcpAzs(azs, instanceTypes?.instanceTypes, selectedInstance);
  }
);

export const getGCPInstanceTypes = createSelector(
  gcpInstanceTypesFetcher.selector,
  ({ result }) => {
    if (!result) {
      return [];
    }

    return parseInstanceTypesResponse(result.instanceTypes);
  }
);

export const getDatacenterClustersOptions = createSelector(
  datacentersFetcher.selector,
  getClusterCloudConfig,
  (datacentersState, cloudConfig) => {
    const center = datacentersState?.result?.find(
      ({ datacenter }) =>
        datacenter === cloudConfig.spec.clusterConfig.placement.datacenter
    );
    return (center?.computeclusters || center?.computeClusters || []).map(
      (computecluster) => ({
        label: computecluster.name || computecluster,
        value: computecluster.name || computecluster,
      })
    );
  }
);

export const isStaticPlacementEnabled = createSelector(
  getClusterCloudConfig,
  (config) => {
    return !!config?.spec?.clusterConfig?.staticIp;
  }
);

export const getVIPEndpoint = createSelector(
  isStaticPlacementEnabled,
  getCluster,
  (isStatic, cluster) => {
    const [apiEndpoint] = cluster.status?.apiEndpoints || [];
    return isStatic && apiEndpoint?.host;
  }
);

export const getTotalRates = createSelector(
  (state) => state.cluster?.nodes?.totalRates,
  (data) => data?.rate
);

export const getNodePoolEstimatedRate = createSelector(
  (state) => state.cluster?.nodes?.nodePoolRates,
  (data) => data?.rate || {}
);

export const getAwsSelectedInstance = createSelector(
  (state) => state.cluster.details.cloudConfigParams,
  (state) => state.forms.nodePool?.data?.instanceType,
  (params, type) => findInstanceByType(params, type)
);

export const getAzureSelectedInstance = createSelector(
  azureInstanceTypesFetcher.selector,
  (state) => state.forms.nodePool?.data?.instanceType,
  ({ result }, type) => findInstanceByType(result, type)
);

export const getGcpSelectedInstance = createSelector(
  gcpInstanceTypesFetcher.selector,
  (state) => state.forms.nodePool?.data?.instanceType,
  ({ result }, type) => findInstanceByType(result, type)
);

export const getNodePoolSelectedAppliancesGPUs = createSelector(
  (state) => state.forms.nodePool?.data?.edgeHosts,
  edgeMachinesFetcher.selector,
  (selectedHosts, { result } = {}) => {
    const hosts = (selectedHosts || [])
      .map(({ hostUid }) =>
        (result?.items || []).find(
          (appliance) => appliance.metadata.uid === hostUid
        )
      )
      .filter(Boolean);

    return hosts.flatMap((host) => host?.spec?.device?.gpus || []);
  }
);

export const getNodePoolSelectedAppliancesGPUsVendors = createSelector(
  (state) => getNodePoolSelectedAppliancesGPUs(state),
  (gpus) =>
    [...new Set(gpus.map((gpu) => gpu.vendor))].map((vendor) => ({
      label: vendor,
      value: vendor,
    }))
);

export const getNodePoolSelectedGPUModels = createSelector(
  (state) => getNodePoolSelectedAppliancesGPUs(state),
  (state) => state.forms.nodePool?.data?.gpuVendor,
  (gpus, selectedVendor) =>
    [
      ...new Set(
        gpus
          .filter((gpu) => gpu.vendor === selectedVendor)
          .map((gpu) => gpu.model)
      ),
    ].map((model) => ({
      label: model,
      value: model,
    }))
);

export const canAddTaints = createSelector(
  getNodePools,
  (state) => state.forms.nodePool?.data?.poolName,
  (state) => state.forms.nodePool?.data?.taints,
  (nodePools, currentPoolName, taints) => {
    if (taints?.length) {
      return true;
    }

    const nodePoolWithoutTaints = (nodePools || [])
      .filter((nodePool) => nodePool.name !== currentPoolName)
      .find((nodePool) => !nodePool.taints.length);

    return nodePoolWithoutTaints;
  }
);

export const getAutoscaleDivergence = createSelector(
  getNodePools,
  hasAutoscalePack,
  getClusterCloudType,
  (pools, hasAutoscalePack, cloudType) => {
    if (cloudType !== "eks") {
      return [];
    }
    return pools.filter(pool => {
      const hasAutoscale = pool.minSize && pool.maxSize;
      if (hasAutoscalePack) {
        return !hasAutoscale
      }

      return hasAutoscale;
    }).map(pool => pool.guid)
  }
)
