import { createSelector } from "reselect";
import { platformClusterSettingsFetcher } from "state/nodesautoremediation/services";

export const isPlatformFipsSupportEnabled = createSelector(
  platformClusterSettingsFetcher.selector,
  ({ result }) => {
    if (result?.fips?.mode !== "none") {
      return result?.fips?.mode === "fipsEnabled";
    }

    return result?.tenantClusterSettings?.fips?.mode === "fipsEnabled";
  }
);
