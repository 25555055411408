import { get, set } from "lodash";
import { mergeYaml } from "utils/yaml";
import YAML from "yaml";

export const PARAM_SEPARATOR = "~~param~~";

export function createTwoWaySyncer({ formData, formMeta, values }) {
  function populateValues() {
    const params = (formMeta || []).reduce((accumulator, param) => {
      if (formData[`${PARAM_SEPARATOR}${param.name}`]) {
        return set(
          accumulator,
          param.targetKey,
          formData[`${PARAM_SEPARATOR}${param.name}`]
        );
      }

      return accumulator;
    }, {});

    if (Object.keys(params).length) {
      return mergeYaml(values, YAML.stringify(params)).toString();
    }

    return values;
  }

  function populateForm() {
    return (formMeta || []).reduce((accumulator, param) => {
      const valuesDoc = YAML.parse(values);
      return set(
        accumulator,
        `${PARAM_SEPARATOR}${param.name}`,
        get(valuesDoc, param.targetKey)
      );
    }, {});
  }

  return {
    populateForm,
    populateValues,
  };
}
