import store from "services/store";

import Validator from "services/validator";
import { Missing, isKubernetesName, MaxLength } from "services/validator/rules";
import createActions from "modules/form/actions";

import {
  cloudAccountFetcher,
  cloudAccountFormModal,
} from "state/cloudaccounts/services";
import { createAccount } from "state/cloudaccounts/actions/create";

const validator = new Validator();
validator.addRule(
  ["name", "apiKey", "apiBaseUrl", "environment", "organizationId", "service"],
  Missing()
);
validator.addRule(["name"], isKubernetesName());
validator.addRule(["name"], MaxLength(32));

async function submit(data) {
  const payload = {
    metadata: {
      name: data.name,
    },
    spec: {
      apiKey: data.apiKey.trim(),
      apiBaseUrl: data.apiBaseUrl.trim(),
      environment: data.environment.trim(),
      organizationId: data.organizationId.trim(),
      service: data.service.trim(),
    },
  };

  return store.dispatch(createAccount("coxedge", payload));
}

async function init() {
  let data;
  if (cloudAccountFormModal.data?.uid) {
    data = await store.dispatch(cloudAccountFetcher.fetch());
    store.dispatch({
      type: "SET_ACCOUNT_AS_VALID",
    });
  }

  return Promise.resolve({
    type: "coxedge",
    name: data?.metadata.name || "",
    apiKey: data?.spec.apiKey || "",
    apiBaseUrl: data?.spec.apiBaseUrl || "",
    environment: data?.spec.environment || "",
    organizationId: data?.spec.organizationId || "",
    service: data?.spec.service || "",
  });
}

export const coxedgeAccountFormActions = createActions({
  submit,
  validator,
  init,
});
