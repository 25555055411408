import api from "services/api";

import dataFetcher from "modules/dataFetcher";

export const sandboxClusterQuotaUsageFetcher = dataFetcher({
  selectors: ["sandboxCluster", "quota", "usage"],
  async fetchData(_, scope) {
    const response = await api.get(
      `v1/clustergroups/developerCredit/usage/${scope}`
    );
    return response;
  },
});

export const developerQuotaUsageFetcher = dataFetcher({
  selectors: ["developer", "quota", "usage"],
  async fetchData(_, scope) {
    const response = await api.get(
      `v1/clustergroups/developerCredit/usage/${scope}`
    );
    return response;
  },
});
