import { getEntity } from "utils/entities";
import { RoleSchema } from "utils/schemas";
import { createSelector } from "reselect";

export const getSelectedRole = getEntity(
  (state) => state.roles.details.selectedRoleGuid,
  RoleSchema
);

export const getSelectedRoleDetails = createSelector(
  getSelectedRole,
  (role) => ({
    ...role,
    canDelete: role?.metadata?.annotations?.scope !== "system",
  })
);

export const getSelectedRolePermissionNames = createSelector(
  getSelectedRoleDetails,
  (rolesDetails) =>
    rolesDetails.spec?.permissions?.map((permission) => permission.name)
);
