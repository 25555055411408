import { set } from "lodash";
import omit from "lodash/omit";
import { getPackValuesWithoutPresetsComment } from "utils/parsers";
import { createTwoWaySyncer } from "utils/editor/formMode";
function extractValues(profiles) {
  return profiles.reduce((accumulator, profile) => {
    profile.spec.published.packs.forEach((pack) => {
      accumulator[pack.guid] = getPackValuesWithoutPresetsComment(
        pack.spec?.values
      );
      (pack?.manifests || []).forEach((manifest) => {
        accumulator[manifest.guid] = "";
      });
    });

    return accumulator;
  }, {});
}

function extractIntegrationFormValues(profiles) {
  return profiles.reduce((accumulator, profile) => {
    profile.spec.published.packs.forEach((pack) => {
      const syncer = createTwoWaySyncer({
        formMeta: pack?.spec?.template?.parameters?.inputParameters || [],
        values: pack.spec?.values,
      });
      accumulator[pack.guid] = syncer.populateForm();
    });

    return accumulator;
  }, {});
}

function parseErrors(profile, errors = []) {
  return profile.spec.published?.packs?.reduce((acc, pack) => {
    const packErrors = errors.find(
      (error) => error.name === pack.metadata.name
    )?.errors;
    if (!packErrors || !packErrors.length) {
      return acc;
    }
    return { ...acc, [pack.guid]: packErrors };
  }, {});
}

export function PROFILE_STACK_INIT(nextState, { guid, profiles, options }) {
  nextState[guid] = {
    profiles,
    initialProfiles: profiles,
    selectedLayer: profiles?.[0]?.spec?.published?.packs?.[0]?.guid || null,
    selectedProfile: profiles?.[0]?.guid || null,
    expanded: profiles.map((profile) => profile.guid),
    markForDeletion: [],
    view: "list",
    resolvedValues: [],
    integrationFormValues: extractIntegrationFormValues(profiles),
    errors: {},
    values: extractValues(profiles),
    initialValues: extractValues(profiles),
    initialProfileUids: profiles.map((profile) => profile.metadata.uid),
    options,
    formMode: "packs",
  };
}

export function PROFILE_STACK_SELECT_LAYER(nextState, { guid, layerGuid }) {
  nextState[guid].selectedLayer = layerGuid;
}

export function PROFILE_STACK_RESET_STATE(nextState, { guid }, state) {
  const { initialProfiles } = state[guid];
  nextState[guid].profiles = initialProfiles;
  nextState[guid].selectedLayer =
    initialProfiles?.[0]?.spec?.published?.packs?.[0]?.guid || null;
  nextState[guid].selectedProfile = initialProfiles?.[0]?.guid || null;
  nextState[guid].markForDeletion = [];
  nextState[guid].expanded = initialProfiles?.map((profile) => profile.guid);
  nextState[guid].errors = {};
  nextState[guid].values = extractValues(initialProfiles);
  nextState[guid].initialValues = extractValues(initialProfiles);
  nextState[guid].initialProfileUids = initialProfiles.map(
    (profile) => profile.metadata.uid
  );
  nextState[guid].integrationFormValues =
    extractIntegrationFormValues(initialProfiles);
}

export function PROFILE_STACK_ADD_PROFILE_LOADING(
  nextState,
  { guid, loading }
) {
  nextState[guid].loading = loading;
}

export function PROFILE_STACK_ADD_PROFILE(nextState, { guid, profile }, state) {
  nextState[guid].profiles.push({
    ...profile,
    type: profile?.spec?.published?.type || "add-on",
  });
  nextState[guid].expanded.push(profile.guid);
  nextState[guid].values = {
    ...state[guid].values,
    ...extractValues([profile]),
  };

  nextState[guid].integrationFormValues = {
    ...state[guid].integrationFormValues,
    ...extractIntegrationFormValues([profile]),
  };
}

export function PROFILE_STACK_SELECT_PROFILE(nextState, { guid, profileGuid }) {
  nextState[guid].selectedProfile = profileGuid;
  nextState[guid].view = "details";
}

export function PROFILE_STACK_SET_LAYERS_ERRORS(
  nextState,
  { guid, errors },
  state
) {
  nextState[guid].errors = state[guid].profiles.reduce((acc, profile) => {
    const profileErrors = errors.find(
      (error) => error.uid === profile.metadata.uid
    )?.results;

    if (!profileErrors?.length) {
      return acc;
    }

    return { ...acc, ...parseErrors(profile, profileErrors) };
  }, {});
}

export function PROFILE_STACK_VIEW_LIST(nextState, { guid }) {
  nextState[guid].view = "list";
}

export function PROFILE_STACK_VALUE_CHANGE(
  nextState,
  { guid, values, layer, isChangePersisted }
) {
  if (isChangePersisted) {
    nextState[guid].initialValues[layer] = values;
  }

  nextState[guid].values[layer] = values;
}

export function UPDATE_RESOLVED_VALUES(nextState, { guid, resolvedValues }) {
  if (!resolvedValues) {
    return;
  }
  nextState[guid].resolvedValues.push(...resolvedValues);
}

export function PROFILE_STACK_DELETE_PROFILE(
  nextState,
  { profileToDelete, guid },
  state
) {
  const indexOfProfile = state[guid].initialProfiles.findIndex(
    (profile) => profile.guid === profileToDelete.guid
  );

  const packs = profileToDelete?.spec?.published?.packs || [];

  nextState[guid].values = omit(state[guid].values, [
    ...packs.map((pack) => pack.guid),
    ...packs
      .map((pack) => (pack?.manifests || []).map((manifest) => manifest.guid))
      .flat(),
  ]);

  if (indexOfProfile !== -1) {
    nextState[guid].markForDeletion.push(profileToDelete.guid);
    return;
  }

  const removeIndex = state[guid].profiles.findIndex(
    (profile) => profile.guid === profileToDelete.guid
  );
  nextState[guid].profiles.splice(removeIndex, 1);
}

export function PROFILE_STACK_UNDELETE_PROFILE(
  nextState,
  { profileToKeep, guid },
  state
) {
  const currentIndex = state[guid].markForDeletion.indexOf(profileToKeep.guid);

  if (currentIndex === -1) {
    return;
  }

  nextState[guid].values = {
    ...state[guid].values,
    ...extractValues([profileToKeep]),
  };

  nextState[guid].markForDeletion.splice(currentIndex, 1);
}

export function PROFILE_STACK_UPDATE_EXPANDED(
  nextState,
  { profileGuids, guid }
) {
  nextState[guid].expanded = profileGuids;
}

export function PROFILE_STACK_REPLACE_PROFILE(
  nextState,
  { newProfile, currentProfile, guid },
  state
) {
  const currentIndex = state[guid].profiles.findIndex(
    (profile) => profile.guid === currentProfile
  );

  if (currentIndex === -1) {
    return;
  }

  nextState[guid].expanded.push(newProfile.guid);
  nextState[guid].profiles.splice(currentIndex, 1, newProfile);
  nextState[guid].values = {
    ...state[guid].values,
    ...extractValues([newProfile]),
  };

  nextState[guid].integrationFormValues = {
    ...state[guid].integrationFormValues,
    ...extractIntegrationFormValues([newProfile]),
  };
}

export function PROFILE_STACK_INTEGRATION_FIELD_VALUE_CHANGE(
  nextState,
  { guid, name, value, layer }
) {
  set(nextState[guid].integrationFormValues, [layer, name], value);
}

export function PROFILE_STACK_UPDATE_FORM_MODE(nextState, { guid, mode }) {
  nextState[guid].formMode = mode;
}

export function PROFILE_STACK_INTEGRATION_BATCH_UPDATES(
  nextState,
  { guid, layer, params }
) {
  set(nextState[guid].integrationFormValues, [layer], params);
}
