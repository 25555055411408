import store from "services/store";
import i18n from "i18next";
import debounce from "redux-debounce-thunk";
import moment from "moment";
import axios from "axios";

import Validator from "services/validator";
import createFormActions from "modules/form/actions";
import {
  Missing,
  isKubernetesName,
  MaxLength,
  areValidKeyValueTags,
} from "services/validator/rules";
import { NodeSchema, NodePoolSchema, CloudConfigSchema } from "utils/schemas";
import {
  BAREMETAL_ENVS,
  SPECTRO_TAG,
  UPDATE_STRATEGIES,
} from "utils/constants";

import { round } from "utils/number";

import {
  getAddedNodes,
  getNodePools,
  getClusterCloudConfig,
  getSelectedNodePool,
  getSubnetsForSelectedAz,
  getAzureAzs,
  isStaticPlacementEnabled,
  getAzureInstanceTypes,
  getSystemNodePools,
  getAllNodes,
} from "state/cluster/selectors/nodes";
import {
  getCluster,
  getClusterImport,
  hasAutoscalePack,
} from "state/cluster/selectors/details";
import { getClusterByUid } from "./details";

import { pollNodes } from "utils/tasks";
import api from "services/api";
import ModalService from "services/modal";
import notifications from "services/notifications";
import i18next from "i18next";

import { utilizationFetcher, hostClusterFetcher } from "state/cluster/services";
import {
  nodePoolAppliancesFetcher,
  nodePoolApplianceResourceFetchers,
  nodePoolApplianceNetworksKeyedFetcher,
  azureInstanceTypesFetcher,
  azureAZFetcher,
  azureStorageAccountsFetcher,
  gcpAZFetcher,
  gcpInstanceTypesFetcher,
  nodeDetailsModalService,
  NODE_DETAILS_FILTERS_MODULE,
  DEFAULT_RESOURCE_POOL_VALUE,
  connectNodeModal,
  poolConfigModal,
  confirmNodePoolSizeModal,
  addNodePoolModal,
} from "state/cluster/services/nodes";
import {
  datacentersFetcher,
  propertiesFetcher,
  ipamFetcher,
  appliancesKeyedFetcher,
  vsphereAppliancesFetcher,
} from "state/cluster/services/create";
import { dnsMappingsFetcher } from "state/dns/services";

import { createOpenstackFormFactory } from "modules/cluster/openstack";
import { createMaasFormFactory } from "modules/cluster/maas";
import { createTencentFormFactory } from "modules/cluster/tencent";
import { edgeMachinesFetcher } from "./list/edgemachines";
import { cloneDeep } from "lodash";
import { mapCloudType } from "utils/presenters";
import { parseLabelsForInput } from "utils/parsers";
import { getNodeCommonPoolConfig } from "../selectors/create";
import { fetchNodesConfigParams } from "./create/flows/cox";

const commonValidator = new Validator();
commonValidator.addRule(["poolName", "size"], Missing());
commonValidator.addRule("size", (size, _, nodePool) => {
  const cloudType = getCluster(store.getState())?.spec?.cloudType;
  if (cloudType === "edge-native" && size >= 1) {
    return false;
  }

  if (nodePool.isControlPlane && size % 2 === 0) {
    return i18n.t("Master node pool size should be an odd number");
  }

  return false;
});
commonValidator.addRule(["poolName"], isKubernetesName());
commonValidator.addRule(["poolName"], MaxLength(63));
commonValidator.addRule("poolName", (poolName) => {
  const selectedNodePool = getSelectedNodePool(store.getState());
  const nodePools = getNodePools(store.getState());

  if (selectedNodePool) {
    return false;
  }
  if (nodePools?.find((nodePool) => nodePool.name === poolName)) {
    return i18n.t("Node pool name already exists");
  }
  return false;
});

export const openstackNodesForm = createOpenstackFormFactory(
  {
    getCloudAccountUid(state) {
      return getClusterCloudConfig(state)?.spec?.cloudAccountRef?.uid;
    },
    getClusterConfig(state) {
      const config = getClusterCloudConfig(state)?.spec?.clusterConfig;

      return {
        domain: config?.domain?.name,
        region: config?.region,
        project: config?.project?.name,
        staticPlacement: !!config?.network?.name,
      };
    },
  },
  { isNodes: true }
);

export const maasNodesForm = createMaasFormFactory(
  {
    getCloudAccountUid(state) {
      return getClusterCloudConfig(state)?.spec?.cloudAccountRef?.uid;
    },
  },
  { isNodes: true }
);

const awsNodePoolValidator = new Validator();
awsNodePoolValidator.addRule(["instanceType", "azs"], Missing());
awsNodePoolValidator.addRule("maxPricePercentage", (value, key, data) => {
  if (data.instanceOption === "onSpot") {
    return Missing()(value, key, data);
  }

  return false;
});
awsNodePoolValidator.addRule(function* (nodePool) {
  const staticPlacement = getSubnetsForSelectedAz(store.getState());
  for (const az of nodePool.azs) {
    yield {
      result:
        staticPlacement && !nodePool[`subnet_${az}`]
          ? i18next.t("Missing subnet for selected az")
          : false,
      field: `subnet_${az}`,
    };
  }
});

const eksNodePoolValidator = new Validator();
eksNodePoolValidator.addRule(["instanceType"], Missing());
eksNodePoolValidator.addRule(["azs"], (value, key, data) => {
  const clusterConfig =
    getClusterCloudConfig(store.getState())?.spec?.clusterConfig || {};

  if (clusterConfig.vpcid || clusterConfig.vpcId) {
    return Missing()(value, key, data);
  }
  return false;
});
eksNodePoolValidator.addRule(function* (nodePool) {
  const staticPlacement = getSubnetsForSelectedAz(store.getState());
  for (const az of nodePool.azs) {
    yield {
      result:
        staticPlacement && !nodePool[`subnet_${az}`]
          ? i18next.t("Missing subnet for selected az")
          : false,
      field: `subnet_${az}`,
    };
  }
});

const openstackNodePoolValidator = new Validator();
openstackNodePoolValidator.addRule(["flavor", "disk", "azs"], Missing());

const vsphereNodePoolValidator = new Validator();
vsphereNodePoolValidator.addRule(["memory", "disk", "cpu"], Missing());

const domainValidator = new Validator();
domainValidator.addRule(
  ["cluster", "datastore", "network"],
  Missing({ message: () => " " })
);
domainValidator.addRule(["parentPoolUid"], (...args) => {
  const useStaticIp = isStaticPlacementEnabled(store.getState());
  if (!useStaticIp) {
    return false;
  }

  return Missing({ message: () => " " })(...args);
});

domainValidator.addRule("cluster", function* (value, key, data) {
  const domains = store.getState().forms.nodePool.data.domains;
  const clusters = domains.map(({ cluster }) => cluster);

  for (const clusterIndex in clusters) {
    const cluster = clusters[clusterIndex];
    const duplicates = clusters.filter(
      (currentItem) => currentItem === cluster
    );
    yield {
      result:
        duplicates.length > 1 ? i18next.t("Clusters must be unique") : false,
      field: `domains.${clusterIndex}.cluster`,
    };
  }
});

vsphereNodePoolValidator.addRule("domains", domainValidator);
vsphereNodePoolValidator.addRule("domains", function* (value, key, data) {
  for (const domainIndex in value) {
    const domain = value[domainIndex];
    yield {
      result:
        data.isControlPlane &&
        data.domains
          .map((domain) => domain.network)
          .some((network) => network !== "" && network !== domain.network)
          ? i18n.t("Master nodes must share the same network")
          : false,
      field: `domains.${domainIndex}.network`,
    };
  }
});

const azurePoolValidator = new Validator();
azurePoolValidator.addRule(
  ["disk", "instanceType", "storageAccountType"],
  Missing()
);

azurePoolValidator.addRule("azs", (value, key, data) => {
  if (data.isMaster || data.isControlPlane) {
    return false;
  }

  const kind = getClusterCloudConfig(store.getState())?.kind;
  if (kind === "aks") {
    return false;
  }

  if (!getAzureAzs(store.getState()).length) {
    return false;
  }

  return Missing()(value, key, data);
});

azurePoolValidator.addRule("isSystemNodePool", (value, key, data) => {
  const kind = getClusterCloudConfig(store.getState())?.kind;

  if (kind === "aks") {
    const nodePools = getNodePools(store.getState());
    const nodePoolsAsSystemPool = (nodePools || []).filter(
      (nodePool) => nodePool.isSystemNodePool
    );

    if (nodePoolsAsSystemPool.length > 1) {
      return false;
    }

    if (
      nodePoolsAsSystemPool.length === 1 &&
      nodePoolsAsSystemPool[0].name === data.poolName
    ) {
      return value
        ? false
        : i18n.t("At least one pool must be set to be system");
    }
  }

  return false;
});

const googleNodePoolValidator = new Validator();
googleNodePoolValidator.addRule(["instanceType", "azs"], Missing());

const maasNodePoolValidator = new Validator();
maasNodePoolValidator.addRule(["azs", "resourcePool"], Missing());

const taintsValidator = new Validator();
taintsValidator.addRule(["key", "value", "effect"], Missing());

const tencentNodePoolValidator = new Validator();
tencentNodePoolValidator.addRule(["instanceType"], Missing());
tencentNodePoolValidator.addRule(["azs"], (value, key, data) => {
  const clusterConfig =
    getClusterCloudConfig(store.getState())?.spec?.clusterConfig || {};
  if (clusterConfig.vpcid || clusterConfig.vpcID) {
    return Missing()(value, key, data);
  }
  return false;
});

const edgeNativeNodePoolValidator = new Validator();
const edgeHostsValidator = new Validator();
edgeHostsValidator.addRule(["hostUid"], Missing());

edgeNativeNodePoolValidator.addRule(["edgeHosts"], edgeHostsValidator);

const coxEdgeNodePoolValidator = new Validator();
const deploymentValidator = new Validator();
deploymentValidator.addRule(["name", "pops"], Missing({ message: () => " " }));
coxEdgeNodePoolValidator.addRule(["deployments"], deploymentValidator);
coxEdgeNodePoolValidator.addRule(["instanceType"], Missing());

const VALIDATOR_MAPPING = {
  aws: awsNodePoolValidator,
  eks: eksNodePoolValidator,
  vsphere: vsphereNodePoolValidator,
  azure: azurePoolValidator,
  aks: azurePoolValidator,
  gcp: googleNodePoolValidator,
  maas: maasNodePoolValidator,
  openstack: openstackNodePoolValidator,
  tke: tencentNodePoolValidator,
  "edge-native": edgeNativeNodePoolValidator,
  coxedge: coxEdgeNodePoolValidator,
};
const nodePoolValidator = new Validator();
nodePoolValidator.addRule(function* () {
  yield commonValidator;
  const clusterConfig = getClusterCloudConfig(store.getState());
  const kind = clusterConfig.kind || clusterConfig.metadata.kind;
  if (VALIDATOR_MAPPING[kind]) {
    yield VALIDATOR_MAPPING[kind];
  }
});

nodePoolValidator.addRule(["taints"], function* (value, key, data) {
  if (data.withTaints) {
    yield taintsValidator;
  }
});

nodePoolValidator.addRule(["additionalLabels"], function (value) {
  for (const item of value) {
    const [key, val] = item.split(":");
    if (val === SPECTRO_TAG || !key || !val) {
      return i18next.t(`Label "{{item}}" is invalid`, { item });
    }
  }
  const keys = value.reduce((acc, item) => {
    if (item.includes(":")) {
      const [key] = item.split(":");
      acc.push(key);
      return acc;
    }
    acc.push(item);
    return acc;
  }, []);
  const set = new Set(keys);
  return keys.length !== set.size
    ? i18next.t(`Duplicate keys are not allowed`)
    : areValidKeyValueTags()(value);
});

function getCommonPayload(data) {
  return {
    ...getNodeCommonPoolConfig({
      ...data,
      isMaster: data.isControlPlane,
      maxNodeSize: data.maxSize,
      minNodeSize: data.minSize,
    }),
  };
}

const PAYLOAD_MAPPING = {
  aws(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        capacityType: data.instanceOption === "onSpot" ? "spot" : "on-demand",
        spotMarketOptions:
          data.instanceOption === "onSpot"
            ? {
                maxPrice: `${(
                  (data.instancePrice * data.maxPricePercentage) /
                  100
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 5,
                })}`,
              }
            : undefined,
        azs: data.azs,
        rootDeviceSize: data?.disk,
        subnets: data.azs.reduce((acc, az) => {
          const subnetIsSet = data[`subnet_${az}`];

          if (subnetIsSet) {
            acc.push({
              az,
              id: subnetIsSet.join(","),
            });
          }
          return acc;
        }, []),
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  eks(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        azs: data.azs,
        rootDeviceSize: data?.disk,
        capacityType: data.instanceOption === "onSpot" ? "spot" : "on-demand",
        subnets: (data?.azs || []).reduce((acc, az) => {
          const subnetIsSet = data[`subnet_${az}`];

          if (subnetIsSet) {
            acc.push({
              az,
              id: subnetIsSet.join(","),
            });
          }
          return acc;
        }, []),
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  vsphere(data) {
    const useStaticIp = isStaticPlacementEnabled(store.getState());
    return {
      cloudConfig: {
        instanceType: {
          diskGiB: data?.disk,
          memoryMiB: data?.memory * 1024,
          numCPUs: data?.cpu,
        },
        placements: data.domains.map(
          ({ network, parentPoolUid, dns, resourcePool, ...rest }) => ({
            ...rest,
            resourcePool:
              resourcePool === DEFAULT_RESOURCE_POOL_VALUE ? "" : resourcePool,
            network: {
              networkName: network,
              staticIp: useStaticIp,
              parentPoolUid,
            },
          })
        ),
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  azure(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        azs: data.azs,
        osDisk: {
          diskSizeGB: data.disk,
          managedDisk: {
            storageAccountType: data.storageAccountType,
          },
          osType: data.osType,
        },
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },

  aks(data) {
    return {
      managedPoolConfig: {
        isSystemNodePool: data.isSystemNodePool,
        osType: data.isSystemNodePool ? "Linux" : data.osType,
      },
      cloudConfig: {
        instanceType: data.instanceType,
        azs: data.azs,
        osDisk: {
          diskSizeGB: data.disk,
          managedDisk: {
            storageAccountType: data.storageAccountType,
          },
        },
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  gcp(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        azs: data.azs,
        rootDeviceSize: data.disk,
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  maas(data) {
    return {
      cloudConfig: {
        instanceType: {
          minCPU: data.minCPU,
          minMemInMB: data.minMem * 1024,
        },
        azs: data.azs,
        resourcePool: data.resourcePool,
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  openstack(data) {
    const selectors = openstackNodesForm.selectors;
    const state = store.getState();
    const subnets = selectors.getOpenstackNetworkSubnets(state);
    const flavorsData =
      openstackNodesForm.fetchers.flavorsFetcher.selector(state);
    const flavorConfig = (flavorsData.result || []).find(
      (flavor) => flavor.name === data.flavor
    );
    const subnet = subnets.find((subnet) => subnet.id === data.subnet);

    return {
      cloudConfig: {
        flavorConfig: flavorConfig
          ? {
              numCPUs: flavorConfig.vcpus,
              memoryMiB: flavorConfig.memory,
              name: flavorConfig.name,
              diskGiB: flavorConfig.disk,
            }
          : undefined,
        azs: data.azs,
        diskGiB: data.disk,
        subnet,
      },
      poolConfig: {
        ...getCommonPayload(data),
      },
    };
  },
  edge(data) {
    return {
      cloudConfig: {
        edgeHosts: data.edgeHosts,
      },
      poolConfig: {
        ...getCommonPayload(data),
        maxSize: undefined,
        minSize: undefined,
      },
    };
  },
  "edge-native": (data) => ({
    cloudConfig: {
      edgeHosts: data.edgeHosts,
    },
    poolConfig: {
      ...getCommonPayload(data),
      maxSize: undefined,
      minSize: undefined,
    },
  }),
  libvirt(data) {
    return {
      cloudConfig: {
        instanceType: {
          numCPUs: data.cpu,
          memoryInMB: data.memory * 1024,
          cpuset: data?.cpuset,
          cpuPassthroughSpec: data.cpuPassthroughSpec,
          gpuConfig: data.gpuSupport
            ? {
                numGPUs: data.numGPUs,
                vendorName: data.gpuVendor,
                deviceModel: data.gpuModel,
              }
            : undefined,
        },
        placements: data.edgeHosts.map((edgeHost) => ({
          hostUid: edgeHost.hostUid,
          dataStoragePool: edgeHost.dataStoragePool,
          sourceStoragePool: edgeHost.sourceStoragePool,
          targetStoragePool: edgeHost.targetStoragePool,
          networks: (edgeHost?.networks || []).map((network) => ({
            networkName: network,
            networkType: edgeHost.networkType,
          })),
        })),
        rootDiskInGB: data.disk,
        nonRootDisksInGB: (data?.nonRootDisks || []).map((diskSize) => ({
          sizeInGB: parseInt(diskSize),
          managed: data?.persistentNonRootDisks,
        })),
      },
      poolConfig: {
        ...getCommonPayload(data),
        maxSize: undefined,
        minSize: undefined,
      },
    };
  },
  tke(data) {
    return {
      cloudConfig: {
        instanceType: data.instanceType,
        azs: data.azs,
        rootDeviceSize: data?.disk,
        subnetIds: (data.azs || []).reduce((acc, az) => {
          const azSubnets = data[`subnet_${az}`];
          const id = Array.isArray(azSubnets) ? azSubnets.join(",") : azSubnets;

          if (id) {
            return { ...acc, [az]: id };
          }
          return acc;
        }, {}),
      },
      poolConfig: {
        ...getCommonPayload(data),
        maxSize: undefined,
        minSize: undefined,
        size: data.size,
      },
    };
  },
  coxedge(data) {
    const inboundRules = data.network?.inboundRules || [];
    const outboundRules = data.network?.outboundRules || [];

    return {
      cloudConfig: {
        spec: data.instanceType,
        deployments: (data?.deployments || []).map(
          ({
            enableAutoScaling,
            minInstancesPerPop,
            maxInstancesPerPop,
            instancesPerPop,
            ...deployment
          }) => ({
            ...deployment,
            pops: [deployment.pops],
            ...(enableAutoScaling
              ? {
                  minInstancesPerPop,
                  maxInstancesPerPop,
                }
              : {
                  instancesPerPop,
                }),
          })
        ),
        securityGroupRules: [...inboundRules, ...outboundRules].map((rule) => ({
          ...rule,
        })),
      },
      poolConfig: {
        ...getCommonPayload(data),
        useControlPlaneAsWorker: !!data?.useControlPlaneAsWorker,
      },
    };
  },
};

export function fetchClusterCloudConfig() {
  return async function thunk(dispatch, getState) {
    const cluster = getCluster(getState());
    const { cloudType, cloudConfigRef } = cluster.spec;
    const type = cloudType === "all" ? "generic" : mapCloudType(cloudType);

    if (!cloudConfigRef) {
      return Promise.resolve(null);
    }

    const clusterCloudConfigPromise = api.get(
      `v1/cloudconfigs/${type}/${cloudConfigRef.uid}`
    );

    dispatch({
      promise: clusterCloudConfigPromise,
      type: "FETCH_CLUSTER_CLOUD_CONFIG",
      schema: CloudConfigSchema,
    });

    try {
      await clusterCloudConfigPromise;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      notifications.error({
        message: i18n.t(
          "Something went wrong when getting the cluster cloud config"
        ),
        description: error?.message,
      });
      return;
    }

    return clusterCloudConfigPromise;
  };
}

export function fetchAwsCloudConfigParams({ type = null } = {}) {
  return async function thunk(dispatch, getState) {
    const state = getState();
    let region, cloudAccountUid, response;

    if (type === "create") {
      region = state.forms.cluster.data?.region;
      cloudAccountUid = state.forms.cluster.data?.credential;
    } else {
      const clusterCloudConfig = getClusterCloudConfig(getState());
      region = clusterCloudConfig.spec.clusterConfig.region;
      cloudAccountUid = clusterCloudConfig.spec.cloudAccountRef.uid;
    }

    const promise = Promise.all([
      api.get(
        `v1/clouds/aws/regions/${region}/availabilityzones?cloudAccountUid=${cloudAccountUid}`
      ),
      api.get(
        `v1/clouds/aws/regions/${region}/instancetypes?cloudAccountUid=${cloudAccountUid}`
      ),
      api.get(
        `v1/clouds/aws/regions/${region}/vpcs?cloudAccountUid=${cloudAccountUid}`
      ),
    ]).then(([zones, instanceTypes, vpcs]) => ({
      azs: zones.zones,
      instanceTypes: instanceTypes.instanceTypes,
      vpcids: vpcs.vpcs,
    }));

    dispatch({
      promise,
      type: "FETCH_CLOUD_CONFIG_PARAMS",
    });

    try {
      response = await promise;
    } catch (err) {
      return {};
    }

    return response;
  };
}

export function addNode(nodeUuid, nodePoolName) {
  return {
    type: "ADD_NODE",
    addedNodeUuid: nodeUuid,
    nodePoolName,
  };
}

function removeNode(nodeUuid, nodePoolName) {
  return {
    type: "REMOVE_NODE",
    removedNodeUuid: nodeUuid,
    nodePoolName,
  };
}

export function toggleNode(nodeUuid, nodePoolName) {
  return (dispatch, getState) => {
    const currentClusterId = getState().cluster.details.currentClusterId;
    const state = getState().entities.cluster[currentClusterId];
    const npName = nodePoolName || state.spec.nodePools[0].name;
    const addedNodes = getState().cluster.nodes.addedNodes;
    const checked = addedNodes.includes(nodeUuid);

    if (checked) {
      dispatch(removeNode(nodeUuid, npName));
    } else {
      dispatch(addNode(nodeUuid, npName));
    }
  };
}

export function connectNodes() {
  return (dispatch, getState) => {
    const addedNodes = getState().cluster.nodes.addedNodes;
    const nodePoolToAddGuid = getState().cluster.nodes.nodePoolToAddGuid;
    const nodePoolToAdd = getNodePools(getState()).find(
      (nodePool) => nodePool.guid === nodePoolToAddGuid
    );

    const promise = new Promise((resolve) => {
      setTimeout(resolve, 200, [...getAddedNodes(getState())]);
    });

    dispatch({
      promise,
      addedNodes,
      type: "CONNECT_NODES",
      schema: [NodeSchema],
    });

    promise.then(() => {
      dispatch({
        type: "UPDATE_ENTITY",
        entityType: "nodePools",
        id: nodePoolToAddGuid,
        updates: {
          nodes: [
            ...nodePoolToAdd.nodes.map((node) => node.guid),
            ...addedNodes,
          ],
        },
      });

      addedNodes.forEach((nodeGuid) => {
        dispatch({
          type: "UPDATE_ENTITY",
          entityType: "node",
          id: nodeGuid,
          updates: { status: "pending" },
        });
      });

      dispatch(connectNodeModal.close());
    });
  };
}

export const updateClusterDebounce = debounce(sendNodePoolSize, 1000);

export function sendNodePoolSize(nodePoolGuid) {
  return async (dispatch, getState) => {
    const nodePool = getNodePools(getState()).find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );
    const cluster = getCluster(getState());
    const clusterCloudConfig = getClusterCloudConfig(getState());

    const desiredNodePoolSize =
      getState().cluster.nodes.desiredNodePoolSizes[nodePool.name];

    let promise;

    const kind = clusterCloudConfig?.kind || clusterCloudConfig?.metadata?.kind;
    const formData = await POPULATE_FIELDS_MAPPING[kind](nodePool);
    const cloudType = mapCloudType(cluster.spec.cloudType);

    const payload = PAYLOAD_MAPPING[kind](formData);
    payload.poolConfig.size = desiredNodePoolSize;

    if (!nodePool.persisted) {
      promise = api.post(
        `v1/cloudconfigs/${cloudType}/${clusterCloudConfig.metadata.uid}/machinePools`,
        payload
      );
    } else {
      promise = api.put(
        `v1/cloudconfigs/${cloudType}/${clusterCloudConfig.metadata.uid}/machinePools/${nodePool.name}`,
        payload
      );
    }

    dispatch({
      promise,
      type: "UPDATE_CLUSTER",
    });

    try {
      await promise;
      notifications.info({
        message: i18n.t("Cluster update will begin shortly"),
      });
    } catch (error) {
      const message = nodePool.persisted
        ? i18n.t("Something went wrong when editing the node pool")
        : i18n.t("Something went wrong when creating the node pool");

      dispatch({
        type: "UPDATE_DESIRED_NODE_POOL_SIZE",
        nodePoolName: nodePool.name,
        size: nodePool.size,
      });

      if (axios.isCancel(error)) {
        return;
      }

      notifications.error({
        message,
        description: error?.message,
      });
      return;
    }

    await dispatch(fetchClusterCloudConfig());
    await dispatch(fetchClusterNodes());
    dispatch(fetchClusterEstimatedRate());
    pollNodes.start();
  };
}

// TODO this is for baremetal. should not be a separated function
export function configureNodes() {
  return async (dispatch, getState) => {
    const nodePoolGuid = getState().cluster.nodes.nodePoolToConfigureGuid;
    const nodePools = getNodePools(getState());
    const nodePoolToConfigure = nodePools.find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );

    const promise = new Promise((resolve) => {
      setTimeout(resolve, 300);
    });

    dispatch({
      promise,
      type: "CONFIGURE_NODES",
    });

    promise.then(() => {
      nodePoolToConfigure.nodes.forEach((node) => {
        dispatch({
          type: "UPDATE_ENTITY",
          entityType: "node",
          id: node.guid,
          updates: { status: "configured" },
        });
      });

      dispatch(poolConfigModal.close());
    });
  };
}

export function setNodePoolToAdd(nodePoolGuid) {
  return {
    type: "SET_NODE_POOL_TO_ADD",
    nodePoolToAddGuid: nodePoolGuid,
  };
}

export function setNodePoolToConfigure(nodePoolGuid) {
  return (dispatch) => {
    dispatch({
      type: "SET_NODEPOOL_TO_CONFIGURE",
      nodePoolToConfigureGuid: nodePoolGuid,
    });
  };
}

export function showNodeDetails(nodeUuid) {
  return {
    type: "SHOW_NODE_DETAILS",
    nodeUuid,
  };
}

export function hideNodeDetails() {
  return {
    type: "HIDE_NODE_DETAILS",
  };
}

export function pauseNode() {
  return (dispatch, getState) => {
    const nodeUuid = getState().cluster.nodes.currentNodeOverview;
    const promise = new Promise((resolve) => setTimeout(resolve, 500, {}));

    dispatch({
      type: "PAUSE_NODE",
      promise,
    });

    promise.then(() => {
      dispatch({
        type: "UPDATE_ENTITY",
        entityType: "node",
        id: nodeUuid,
        updates: { paused: true },
      });
    });
  };
}

export function resumeNode() {
  return (dispatch, getState) => {
    const nodeUuid = getState().cluster.nodes.currentNodeOverview;
    const promise = new Promise((resolve) => setTimeout(resolve, 500, {}));

    dispatch({
      type: "RESUME_NODE",
      promise,
    });

    promise.then(() => {
      dispatch({
        type: "UPDATE_ENTITY",
        entityType: "node",
        id: nodeUuid,
        updates: { paused: false },
      });
    });
  };
}

export function fetchAzureNodeParams() {
  return function (dispatch) {
    dispatch(azureInstanceTypesFetcher.fetch());
    dispatch(azureAZFetcher.fetch());
    dispatch(azureStorageAccountsFetcher.fetch());
  };
}

export function fetchGoogleCloudNodeParams() {
  return function (dispatch) {
    dispatch(gcpAZFetcher.fetch());
    dispatch(gcpInstanceTypesFetcher.fetch());
  };
}

export function fetchMaasCloudNodeParams(maasModule) {
  return function (dispatch) {
    dispatch(maasModule.fetchers.resourcePoolsFetcher.fetch());
    dispatch(maasModule.fetchers.azsFetcher.fetch());
  };
}

export function fetchVmWareNodeParams() {
  return async function (dispatch, getState) {
    await dispatch(datacentersFetcher.fetch());
    const useStaticIps = isStaticPlacementEnabled(getState());
    if (useStaticIps) {
      dispatch(ipamFetcher.fetch());
    }
  };
}

export function fetchBaremetalParams(isEdit = false) {
  return async (dispatch, getState) => {
    await dispatch(edgeMachinesFetcher.fetch());

    if (isEdit) {
      dispatch(nodePoolAppliancesFetcher.fetch());

      (getState().forms?.nodePool?.data?.edgeHosts || []).forEach(
        (_, index) => {
          nodePoolApplianceResourceFetchers.forEach((fetcher) =>
            dispatch(fetcher.key(`${index}`).fetch())
          );
        }
      );
    } else {
      const clusterCreateNodePools =
        getState().forms?.cluster?.data?.nodePools || [];
      clusterCreateNodePools.forEach((_, index) => {
        dispatch(appliancesKeyedFetcher.key(`${index}`).fetch());
      });
    }
  };
}

export function onAddNodes(nodePoolGuid) {
  return (dispatch) => {
    dispatch(setNodePoolToAdd(nodePoolGuid));
    connectNodeModal.open().then(() => {
      dispatch(connectNodes());
    });
  };
}

export function addCloudNode(nodePoolGuid) {
  return async (dispatch, getState) => {
    const nodePoolToAdd = getNodePools(getState()).find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );

    const desiredSize = nodePoolToAdd.isControlPlane
      ? nodePoolToAdd.size + 2
      : nodePoolToAdd.size + 1;
    confirmNodePoolSizeModal.open().then(addNodeToCloud);
    const clusterCloudConfig = getClusterCloudConfig(getState());

    if (clusterCloudConfig.kind === "openstack") {
      await dispatch(loadNodePoolCloudProperties());
    }
    dispatch(estimateRatePerNodePool(nodePoolGuid, desiredSize));

    function addNodeToCloud() {
      const loadingAddingNode = getState().cluster.nodes.loadingAddingNode;

      if (loadingAddingNode) {
        return;
      }

      dispatch({
        type: "INSERT_NODE",
        nodePoolName: nodePoolToAdd.name,
        nodesToAdd: nodePoolToAdd.isControlPlane ? 2 : 1,
      });

      dispatch(updateClusterDebounce(nodePoolGuid));
    }
  };
}

export function changeNodePoolSize(nodePoolGuid, size, maxNodes) {
  return (dispatch, getState) => {
    if (maxNodes && size > maxNodes) {
      return;
    }
    const nodePool = getNodePools(getState()).find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );
    dispatch({
      type: "UPDATE_DESIRED_NODE_POOL_SIZE",
      nodePoolName: nodePool.name,
      size,
    });
  };
}

export function updateNodePoolSize(nodePoolGuid) {
  return async (dispatch, getState) => {
    const nodePoolToUpdate = getNodePools(getState()).find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );

    const temporaryNodePoolSize =
      getState().cluster.nodes.temporaryNodePoolSizes[nodePoolToUpdate.name];

    if (!temporaryNodePoolSize) {
      dispatch({
        type: "UPDATE_DESIRED_NODE_POOL_SIZE",
        nodePoolName: nodePoolToUpdate.name,
        size: nodePoolToUpdate.size,
      });

      return;
    }

    if (nodePoolToUpdate.size !== temporaryNodePoolSize) {
      confirmNodePoolSizeModal.open().then(
        () => {
          dispatch({
            type: "UPDATE_NODE_POOL_SIZE",
            nodePoolName: nodePoolToUpdate.name,
          });
          dispatch(sendNodePoolSize(nodePoolGuid));
        },
        () => {
          dispatch({
            type: "UPDATE_DESIRED_NODE_POOL_SIZE",
            nodePoolName: nodePoolToUpdate.name,
            size: nodePoolToUpdate.size,
          });
        }
      );

      const clusterCloudConfig = getClusterCloudConfig(getState());
      if (clusterCloudConfig.kind === "openstack") {
        await dispatch(loadNodePoolCloudProperties());
      }
      dispatch(estimateRatePerNodePool(nodePoolGuid, temporaryNodePoolSize));
    }
  };
}

export function fetchClusterNodes() {
  return async (dispatch, getState) => {
    const state = getState();
    let nodePools = getNodePools(state);
    let clusterCloudConfig = getClusterCloudConfig(state);
    const cluster = getClusterImport(state);

    if (cluster?.isBrownfield || !nodePools.length) {
      await dispatch(fetchClusterCloudConfig());
      nodePools = getNodePools(state);
      clusterCloudConfig = getClusterCloudConfig(state);
    }

    const clusterType =
      clusterCloudConfig?.kind || clusterCloudConfig.metadata.kind;
    const cloudType =
      clusterType === "all" ? "generic" : mapCloudType(clusterType);

    const promises = nodePools.map((nodePool) => {
      if (!nodePool.name) {
        return Promise.resolve({ ...nodePool, nodes: [] });
      }

      const promise = api.get(
        `v1/cloudconfigs/${cloudType}/${clusterCloudConfig.metadata.uid}/machinePools/${nodePool.name}/machines`
      );

      dispatch({
        type: "FETCH_CLUSTER_NODES",
        nodePool: nodePool.guid,
        promise: promise.then((res) => {
          if (!res.items) {
            return { ...nodePool, nodes: [] };
          }

          if (nodePool.minSize && nodePool.maxSize) {
            const activeNodes = res.items.filter((node) => {
              const status = node?.spec?.phase || node?.status?.instanceState;

              return [
                "running",
                "provisioning",
                "provisioned",
                "unknown",
              ].includes(status?.toLowerCase?.());
            });
            dispatch({
              type: "UPDATE_DESIRED_NODE_POOL_SIZE",
              nodePoolName: nodePool.name,
              size: activeNodes.length,
            });
          }

          return {
            ...nodePool,
            nodes: res.items,
          };
        }),
        schema: NodePoolSchema,
      });

      return promise;
    });

    return Promise.all(promises);
  };
}

export function getNodeMetrics() {
  return function thunk(dispatch, getState) {
    const time = getState().forms[NODE_DETAILS_FILTERS_MODULE]?.data?.dateTime;
    const nodeUid = nodeDetailsModalService.data?.nodeUid;

    const periods = {
      "1 hours": 10,
      "6 hours": 30,
      "12 hours": 60,
      "24 hours": 60,
      "1 weeks": 1440,
      "1 months": 1440,
    };

    const query = {
      startTime: moment()
        .subtract(...time.split(" "))
        .utc()
        .format(),
      endTime: moment().utc().format(),
      period: periods[time],
    };

    dispatch(utilizationFetcher.fetch(nodeUid, query, "machine"));
  };
}

export function onTimerangeChange(value) {
  return async (dispatch) => {
    await dispatch(
      utilizationMetricsFormActions.onChange({
        module: NODE_DETAILS_FILTERS_MODULE,
        name: "dateTime",
        value,
      })
    );
    dispatch(getNodeMetrics());
  };
}

export const utilizationMetricsFormActions = createFormActions({
  init() {
    return Promise.resolve({
      utilizationType: "cpu",
      dateTime: "1 hours",
    });
  },
});

export function onUtilizationTypeChange(value) {
  return (dispatch) => {
    dispatch(
      utilizationMetricsFormActions.onChange({
        module: NODE_DETAILS_FILTERS_MODULE,
        name: "utilizationType",
        value,
      })
    );
  };
}

export function openNodeDetailsModal({ nodeUid, isMaster }) {
  return async (dispatch) => {
    if (!nodeUid) {
      return;
    }
    nodeDetailsModalService.open({ nodeUid, isMaster });
    await dispatch(
      utilizationMetricsFormActions.init({
        module: NODE_DETAILS_FILTERS_MODULE,
      })
    );
    dispatch(getNodeMetrics(nodeUid));
  };
}

export function fetchClusterAndNodes(uid) {
  return async (dispatch, getState) => {
    let cluster = getCluster(getState());

    if (!cluster || cluster.metadata.uid !== uid) {
      await dispatch(getClusterByUid(uid));
      cluster = getCluster(getState());
    }

    const isDevMode = getState().auth.devMode;
    if (cluster?.spec?.cloudType === "nested" && !isDevMode) {
      await dispatch(hostClusterFetcher.fetch());
    }

    const nodesFetcher = dispatch(fetchClusterNodes());

    dispatch({
      type: "GETTING_CLUSTER_NODES",
      promise: nodesFetcher,
    });

    await nodesFetcher;

    pollNodes.start();
    return nodesFetcher;
  };
}

function getUpdateStrategyValue(data) {
  return data?.isControlPlane
    ? UPDATE_STRATEGIES[1].value
    : data?.updateStrategy?.type || UPDATE_STRATEGIES[0].value;
}

function getInitialFormDataCommon(data) {
  const isAutoscalerEnabled =
    !data?.isControlPlane && !!(data?.minSize && data?.maxSize);
  return {
    poolName: data?.name || "new-worker-pool",
    size: data?.size || 1,
    minSize: data?.minSize || 1,
    maxSize: data?.maxSize || 3,
    isControlPlane: data?.isControlPlane || false,
    useControlPlaneAsWorker: data?.useControlPlaneAsWorker,
    taints: data?.taints || [],
    additionalLabels: parseLabelsForInput(data?.additionalLabels) || [],
    updateStrategy: getUpdateStrategyValue(data),
    isAutoscalerEnabled,
  };
}

function getSecurityGroupRules(type, data) {
  return (data?.securityGroupRules || [])
    .map((securityRule) => ({
      ...securityRule,
      protocol: (securityRule?.protocol || "").toUpperCase(),
    }))
    .filter((rule) => rule.type === type);
}

function getDeployments(data) {
  if (!data?.deployments) {
    return [{ name: "", pops: "", instancesPerPop: 1 }];
  }

  return (data.deployments || []).map((deployment) => ({
    ...deployment,
    pops: deployment?.pops?.[0],
  }));
}

const POPULATE_FIELDS_MAPPING = {
  async aws(data) {
    let subnets = {};
    if (data?.subnetIds) {
      subnets = Object.keys(data.subnetIds).reduce((acc, key) => {
        const value = data.subnetIds[key] ? data.subnetIds[key].split(",") : [];
        acc[`subnet_${key}`] = value.filter(Boolean);
        return acc;
      }, {});
    }

    const { instanceTypes } = await store.dispatch(fetchAwsCloudConfigParams());

    const instancePrice = data?.instanceType
      ? instanceTypes?.find(({ type }) => type === data?.instanceType)?.price
      : undefined;

    const maxPrice = parseFloat(data?.spotMarketOptions?.maxPrice) || undefined;

    (data?.azs || []).forEach((az) => {
      subnets[`subnet_${az}`] = subnets[`subnet_${az}`] || "";
    });
    return {
      ...getInitialFormDataCommon(data),
      disk: data?.rootDeviceSize || 60,
      azs: data?.azs || [],
      instanceType: data?.instanceType || "",
      instanceOption: data?.capacityType === "spot" ? "onSpot" : "onDemand",
      instancePrice,
      maxPricePercentage:
        instancePrice && maxPrice
          ? round((100 / instancePrice) * maxPrice, 3)
          : undefined,
      ...subnets,
    };
  },
  async eks(data) {
    let subnets = {};
    if (data?.subnetIds) {
      subnets = Object.keys(data.subnetIds).reduce((acc, key) => {
        const value = data.subnetIds[key] ? data.subnetIds[key].split(",") : [];
        acc[`subnet_${key}`] = value;
        return acc;
      }, {});
    }
    (data?.azs || []).forEach((az) => {
      subnets[`subnet_${az}`] = subnets[`subnet_${az}`] || "";
    });
    await store.dispatch(fetchAwsCloudConfigParams());
    return {
      ...getInitialFormDataCommon(data),
      disk: data?.rootDeviceSize || 60,
      azs: data?.azs || [],
      instanceType: data?.instanceType || "",
      instanceOption: data?.capacityType === "spot" ? "onSpot" : "onDemand",
      ...subnets,
    };
  },
  vsphere(data) {
    const useStaticIp = isStaticPlacementEnabled(store.getState());
    let domains = (data?.placements || []).map((placement) => ({
      ...placement,
      disabled: true,
      network: placement.network.networkName,
      resourcePool: !!placement.resourcePool
        ? placement.resourcePool
        : DEFAULT_RESOURCE_POOL_VALUE,
      parentPoolUid: placement.network.parentPoolRef?.uid,
      staticIp: useStaticIp,
    }));

    if (domains.length === 0) {
      domains = [
        {
          cluster: "",
          resourcePool: "",
          datastore: "",
          network: "",
          staticIp: useStaticIp,
          parentPoolUid: "",
        },
      ];
    }

    return {
      ...getInitialFormDataCommon(data),
      disk: data?.instanceType?.diskGiB || 55,
      memory: data?.instanceType?.memoryMiB
        ? data?.instanceType?.memoryMiB / 1024
        : 2,
      cpu: data?.instanceType?.numCPUs || 2,
      domains,
    };
  },
  azure(data) {
    return {
      ...getInitialFormDataCommon(data),
      azs: data?.azs || [],
      instanceType: data?.instanceType,
      disk: data?.osDisk?.diskSizeGB || 60,
      osType: data?.osDisk.osType || "Linux",
      storageAccountType: data?.osDisk?.managedDisk?.storageAccountType,
    };
  },
  aks(data) {
    return {
      ...getInitialFormDataCommon(data),
      azs: data?.azs || [],
      instanceType: data?.instanceType,
      disk: data?.osDisk?.diskSizeGB || 60,
      storageAccountType: data?.osDisk?.managedDisk?.storageAccountType,
      isSystemNodePool: data?.isSystemNodePool || false,
      osType: data?.osType || "Linux",
    };
  },
  gcp(data) {
    return {
      ...getInitialFormDataCommon(data),
      disk: data?.rootDeviceSize || 60,
      azs: data?.azs || [],
      instanceType: data?.instanceType || "",
    };
  },
  maas(data) {
    return {
      ...getInitialFormDataCommon(data),
      minCPU: data?.instanceType?.minCPU || 1,
      minMem: data?.instanceType?.minMemInMB
        ? data?.instanceType?.minMemInMB / 1024
        : 2,
      azs: data?.azs || [],
      resourcePool: data?.resourcePool,
    };
  },
  openstack(data) {
    return {
      ...getInitialFormDataCommon(data),
      size: data?.size || 1,
      azs: data?.azs || [],
      disk: data?.diskGiB || 60,
      flavor: data?.flavorConfig?.name || "",
      subnet: data?.subnet?.name || "",
    };
  },
  edge(data) {
    return {
      ...getInitialFormDataCommon(data),
      disk: data?.diskGiB || 60,
      edgeHosts: [...cloneDeep(data?.hosts || [{ hostUid: "" }])],
    };
  },
  "edge-native": (data) => ({
    ...getInitialFormDataCommon(data),
    size: data?.hosts?.length || 1,
    disk: data?.diskGiB || 60,
    edgeHosts: [...cloneDeep(data?.hosts || [{ hostUid: "" }])],
  }),
  libvirt(data) {
    //TODO: remove once the backend payload is fixed
    const gpuConfig =
      data?.instanceType?.GpuConfig || data?.instanceType?.gpuConfig || {};
    const cpuPassthroughSpec = data?.instanceType?.cpuPassthroughSpec || {};

    return {
      ...getInitialFormDataCommon(data),
      disk: data?.rootDiskInGB || 60,
      cpu: data?.instanceType?.numCPUs || 2,
      memory: data?.instanceType?.memoryInMB / 1024 || 4,
      cpuset: data?.instanceType?.cpuset,
      cpuPassthroughSpec,
      gpuSupport: !!gpuConfig?.vendorName,
      gpuVendor: gpuConfig?.vendorName,
      gpuModel: gpuConfig?.deviceModel,
      numGPUs: gpuConfig?.numGPUs,
      nonRootDisks: (data?.nonRootDisksInGB || []).map((disk) => disk.sizeInGB),
      persistentNonRootDisks: !!data?.nonRootDisksInGB?.[0]?.managed,
      edgeHosts: [...cloneDeep(data?.placements || [{ hostUid: "" }])].map(
        (host) => ({
          hostUid: host.hostUid,
          dataStoragePool: host?.dataStoragePool || "",
          sourceStoragePool: host?.sourceStoragePool || "",
          targetStoragePool: host?.targetStoragePool || "",
          networkType: (host?.networks || [])[0]?.networkType || "",
          networks: (host?.networks || []).map(
            (network) => network?.networkName
          ),
          gpus: host?.gpuDevices || [],
        })
      ),
    };
  },
  tke(data) {
    let subnets = {};

    if (data?.subnetIds) {
      subnets = Object.keys(data.subnetIds).reduce((acc, key) => {
        const value = data.subnetIds[key]?.split(",");
        acc[`subnet_${key}`] = value;
        return acc;
      }, {});
    }

    return {
      ...getInitialFormDataCommon(data),
      disk: data?.rootDeviceSize || 60,
      azs: data?.azs || [],
      instanceType: data?.instanceType || "",
      ...subnets,
    };
  },
  coxedge(data) {
    return {
      ...getInitialFormDataCommon(data),
      deployments: getDeployments(data),
      network: {
        inboundRules: getSecurityGroupRules("inbound", data),
        outboundRules: getSecurityGroupRules("outbound", data),
      },
      instanceType: Object.values(data?.spec || {}).join("") || undefined,
    };
  },
};

export const addNodePoolFormActions = createFormActions({
  validator: nodePoolValidator,
  init: async () => {
    const selectedNodePool = getSelectedNodePool(store.getState());
    const clusterCloudConfig = getClusterCloudConfig(store.getState());

    const cloudType =
      clusterCloudConfig?.kind || clusterCloudConfig?.metadata?.kind;

    if (clusterCloudConfig.spec.edgeHostRef?.uid) {
      await store.dispatch(vsphereAppliancesFetcher.fetch());

      if (cloudType === "vsphere") {
        store.dispatch(datacentersFetcher.fetch());
      }
    }
    store.dispatch({ type: "RESET_ESTIMATED_RATE" });
    const data = await POPULATE_FIELDS_MAPPING[cloudType](selectedNodePool);

    return Promise.resolve({
      ...data,
      withTaints: !!data?.taints?.length,
    });
  },
  submit: async (data) => {
    const state = store.getState();
    const selectedNodePool = getSelectedNodePool(state);
    const currentCluster = getCluster(state);
    const clusterCloudConfig = getClusterCloudConfig(state);
    const { metadata } = clusterCloudConfig;
    const cloudType =
      clusterCloudConfig?.kind || clusterCloudConfig?.metadata?.kind;
    const kind = mapCloudType(cloudType);
    const hasAutoscalerPack = hasAutoscalePack(state);

    const payload = PAYLOAD_MAPPING[cloudType](data);
    if (cloudType === "eks") {
      if (!hasAutoscalerPack) {
        delete payload.poolConfig.maxSize;
        delete payload.poolConfig.minSize;
      } else {
        payload.poolConfig.maxSize = data.maxSize;
        payload.poolConfig.minSize = data.minSize;
        payload.poolConfig.size = data.minSize;
      }
    }

    const endpoint = `v1/cloudconfigs/${kind}/${metadata.uid}/machinePools`;

    const promise = selectedNodePool
      ? api.put(`${endpoint}/${selectedNodePool.name}`, payload)
      : api.post(endpoint, payload);

    try {
      await promise;
    } catch (error) {
      const message = selectedNodePool
        ? i18n.t("Something went wrong when editing the node pool")
        : i18n.t("Something went wrong when creating the node pool");

      notifications.error({
        message,
        description: error.message,
      });

      return Promise.reject();
    }

    const editMessage = i18next.t(
      "Node pool with name '{{poolName}}' was updated successfully. Cluster update will begin shortly",
      { poolName: data.poolName }
    );

    const createMessage = i18next.t(
      "Node pool with name '{{poolName}}' was created successfully. Cluster update will begin shortly",
      { poolName: data.poolName }
    );

    notifications.success({
      message: selectedNodePool ? editMessage : createMessage,
    });

    await store.dispatch(getClusterByUid(currentCluster.metadata.uid));
    await store.dispatch(fetchClusterCloudConfig());
    store.dispatch(fetchClusterEstimatedRate());
    pollNodes.start();
  },
});

export const tencentNodesForm = createTencentFormFactory(
  {
    formModuleName: "nodePool",
    formActions: addNodePoolFormActions,
    getCloudAccountUid(state) {
      return getClusterCloudConfig(state)?.spec?.cloudAccountRef?.uid;
    },
    getClusterConfig(state) {
      const config = getClusterCloudConfig(state)?.spec?.clusterConfig;
      const nodePoolFormData = state.forms?.nodePool?.data || {};
      return {
        ...nodePoolFormData,
        region: config?.region,
        vpcid: config?.vpcID,
      };
    },
  },
  { isNodes: true }
);

// aws params are fetched inside form init
function loadNodePoolCloudProperties() {
  return async (dispatch, getState) => {
    const state = getState();
    const cloudConfig = getClusterCloudConfig(getState());
    const kind = cloudConfig.kind || cloudConfig.metadata.kind;

    if (["aks", "azure"].includes(kind)) {
      dispatch(fetchAzureNodeParams());
    }

    if (kind === "gcp") {
      dispatch(fetchGoogleCloudNodeParams());
    }

    if (kind === "vsphere") {
      dispatch(dnsMappingsFetcher.fetch());
      dispatch(fetchVmWareNodeParams());
    }

    if (kind === "maas") {
      dispatch(fetchMaasCloudNodeParams(maasNodesForm));
    }

    if (kind === "openstack") {
      return openstackNodesForm.effects.fetchProperties();
    }

    if (kind === "tke") {
      return dispatch(tencentNodesForm.effects.fetchNodesConfigParams());
    }

    if (kind === "coxedge") {
      const region = state.forms.nodePool?.data?.deployments?.[0].pops;
      dispatch(fetchNodesConfigParams({ region }));
    }

    if (BAREMETAL_ENVS.includes(kind)) {
      dispatch(fetchBaremetalParams(true));
    }
  };
}

export function openAddNodePoolModal({ type = "", nodePoolGuid } = {}) {
  return (dispatch) => {
    dispatch(loadNodePoolCloudProperties());

    addNodePoolModal.open({ type, nodePoolGuid }).then(
      () => dispatch(addNodePoolFormActions.submit({ module: "nodePool" })),
      () => {
        dispatch(setNodePoolToConfigure());
      }
    );

    dispatch(addNodePoolFormActions.init({ module: "nodePool" }));
  };
}

export const deleteNodeConfirm = new ModalService("deleteNode");

export function deleteNode(node) {
  return async (dispatch, getState) => {
    const state = store.getState();
    const currentCluster = getCluster(state);
    const currentCloudConfig = getClusterCloudConfig(state);
    const kind = currentCloudConfig?.kind || currentCloudConfig?.metadata?.kind;
    const clusterKind = kind === "all" ? "generic" : mapCloudType(kind);
    const poolType = node?.metadata?.annotations?.machinePoolType;
    const poolTypeNodes = (getAllNodes(state) || []).filter(
      (node) => node?.metadata?.annotations?.machinePoolType === poolType
    );

    if (poolTypeNodes.length < 2) {
      notifications.error({
        message: i18n.t(
          "Cannot delete the only Node available. Create another Node in order to delete the current one."
        ),
      });
      return;
    }

    const machinePoolName = node?.metadata?.annotations?.machinePool;
    const machineUid = node?.metadata?.uid;

    deleteNodeConfirm.open().then(async () => {
      const promise = api.delete(
        `v1/cloudconfigs/${clusterKind}/${currentCloudConfig?.metadata?.uid}/machinePools/${machinePoolName}/machines/${machineUid}`
      );
      try {
        await promise;
      } catch (error) {
        notifications.error({
          message: i18n.t("Something went wrong when deleting the node"),
          description: error.message,
        });
        return;
      }
      notifications.success({
        message: i18next.t(
          "Node '{{nodeName}}' has been deleted successfully. Cluster update will begin shortly",
          { nodeName: node?.metadata?.name }
        ),
      });
      await store.dispatch(fetchClusterNodes());
      await store.dispatch(getClusterByUid(currentCluster.metadata.uid));
      await store.dispatch(fetchClusterCloudConfig());
      store.dispatch(fetchClusterEstimatedRate());
    });
  };
}

export const deleteNodePoolConfirm = new ModalService("deleteNodePool");

export function deleteNodePool(nodePoolName) {
  return async (dispatch, getState) => {
    const state = store.getState();
    const currentCluster = getCluster(state);
    const currentCloudConfig = getClusterCloudConfig(state);
    const nodePools = getNodePools(state);
    const cloudType =
      currentCloudConfig?.kind || currentCloudConfig?.metadata?.kind;
    const kind = mapCloudType(cloudType);
    const nodePoolsWithoutTaints = (nodePools || []).filter(
      (nodePool) => !nodePool.taints.length
    );

    if (
      nodePoolsWithoutTaints.length === 1 &&
      nodePoolsWithoutTaints[0].name === nodePoolName
    ) {
      notifications.error({
        message: i18n.t(
          "Cannot delete the only node pool without taints. Create another node pool in order to delete the current one."
        ),
      });
      return;
    }

    if (kind === "aks") {
      const systemNodePools = getSystemNodePools(state);
      const isSystemNodePool = systemNodePools.find(
        (nodePool) => nodePool?.name === nodePoolName
      );

      if (isSystemNodePool && systemNodePools.length < 2) {
        notifications.error({
          message: i18n.t(
            "Cannot delete the only System Node Pool available. Create another System Node Pool in order to delete the current one."
          ),
        });
        return;
      }
    }

    deleteNodePoolConfirm.open().then(async () => {
      const promise = api.delete(
        `v1/cloudconfigs/${kind}/${currentCloudConfig.metadata.uid}/machinePools/${nodePoolName}`
      );

      dispatch({
        type: "DELETE_NODE_POOL",
        promise,
      });

      try {
        await promise;
      } catch (error) {
        notifications.error({
          message: i18n.t("Something went wrong when deleting the node pool"),
          description: error.message,
        });

        return;
      }

      notifications.success({
        message: i18next.t(
          "Node pool with name '{{nodePoolName}}' has been deleted successfully. Cluster update will begin shortly",
          { nodePoolName }
        ),
      });

      await store.dispatch(getClusterByUid(currentCluster.metadata.uid));
      await store.dispatch(fetchClusterCloudConfig());
      store.dispatch(fetchClusterEstimatedRate());
    });
  };
}

export function onDataclusterChange(name, cluster) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[1];
    const domains = [...getState().forms.nodePool.data.domains];

    const prevValue = domains[domainIndex].cluster;

    domains.splice(domainIndex, 1, {
      cluster,
      datastore: "",
      network: "",
      resourcePool: "",
      staticIp: domains[domainIndex].staticIp,
    });
    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );

    dispatch(propertiesFetcher.key(cluster).fetch());

    if (prevValue !== "") {
      const relatedErrors = domains.reduce((accumulator, domain, index) => {
        if (domain.cluster === prevValue && index !== domainIndex) {
          accumulator.push(index);
        }

        return accumulator;
      }, []);

      dispatch(
        addNodePoolFormActions.validateField({
          name: relatedErrors.map((index) => `domains.${index}.cluster`),
          module: "nodePool",
        })
      );
    }
  };
}

export function onNetworkChange(name, network) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[1];
    const domains = [...getState().forms.nodePool.data.domains];
    domains.splice(domainIndex, 1, {
      ...domains[domainIndex],
      network,
    });
    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );

    const relatedErrors = domains.reduce((accumulator, domain, index) => {
      if (domain.network === network && index !== domainIndex) {
        accumulator.push(index);
      }

      return accumulator;
    }, []);

    dispatch(
      addNodePoolFormActions.validateField({
        name: relatedErrors.map((index) => `domains.${index}.network`),
        module: "nodePool",
      })
    );
  };
}

export function onPoolChange(name, value) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[1];
    const domains = [...getState().forms.nodePool.data.domains];
    domains[domainIndex] = {
      ...domains[domainIndex],
      parentPoolUid: value,
    };

    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );
  };
}

export function onAddDomain(name) {
  return (dispatch, getState) => {
    const domains = [...getState().forms.nodePool.data.domains];
    domains.push({
      cluster: "",
      datastore: "",
      network: "",
      resourcePool: "",
      parentPoolUid: "",
    });

    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );
  };
}

export function onDeleteDomain(name) {
  return (dispatch, getState) => {
    const pathParts = name.split(".");
    const domainIndex = pathParts[1];
    const domains = [...getState().forms.nodePool.data.domains];
    domains.splice(domainIndex, 1);

    const errorFields = ["cluster", "datastore", "network"].map(
      (field) => `domains.${domainIndex}.${field}`
    );
    const formErrors = getState().forms.nodePool.errors;
    const updatedErrors = formErrors.map((error) => {
      const shouldRemove = errorFields.includes(error.field);
      if (shouldRemove) {
        return { ...error, result: false };
      }

      return error;
    });

    dispatch(
      addNodePoolFormActions.updateErrors({
        module: "nodePool",
        errors: updatedErrors,
      })
    );

    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          domains,
        },
      })
    );
  };
}

export function onInstanceTypeChange(instanceType) {
  return (dispatch, getState) => {
    const state = getState();
    const initialAzs = state.forms?.nodePool?.initialData?.azs || [];
    const cluster = getCluster(state);
    const { cloudType } = cluster.spec;
    const instanceTypes =
      state.cluster?.details?.cloudConfigParams?.instanceTypes || [];

    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          instanceType,
          azs: initialAzs,
          instancePrice:
            instanceTypes.find(({ type }) => type === instanceType)?.price || 0,
        },
      })
    );

    if (cloudType === "azure") {
      const selectedNodePool = getSelectedNodePool(state);
      const instanceTypes = getAzureInstanceTypes(
        selectedNodePool?.isControlPlane
      )(state);

      const selectedInstanceType = instanceTypes
        .map((types) => types.children)
        .flat()
        .find((instance) => instance.title === instanceType);

      const nonSupportedZones =
        selectedInstanceType.description.props?.nonSupportedZones || [];

      if (nonSupportedZones) {
        const azs = initialAzs.filter((az) => !nonSupportedZones?.includes(az));

        dispatch(
          addNodePoolFormActions.onChange({
            module: "nodePool",
            name: "azs",
            value: azs,
          })
        );
      }
    }
  };
}

export function onInstanceOptionChange(instanceOption) {
  return (dispatch) => {
    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "instanceOption",
        value: instanceOption,
      })
    );
  };
}

export function onAzsChange(value) {
  return (dispatch) => {
    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "azs",
        value,
      })
    );
  };
}

export const debouncedNodePoolChange = debounce(nodePoolChange, 2000);

export function nodePoolChange(data) {
  return async (dispatch) => {
    if (!data) return;

    let errors = [];
    const validations = nodePoolValidator.run(data);

    for await (const error of validations) {
      if (error.result) {
        errors.push(error);
      }
    }

    if (!errors?.length) {
      dispatch(fetchNodePoolEstimatedRate(data));
    } else {
      dispatch({ type: "FETCH_NODE_ESTIMATED_RATE_FAILURE" });
    }
  };
}

export function removeTaint(index) {
  return (dispatch, getState) => {
    const taints = [...getState().forms?.nodePool?.data?.taints];
    taints.splice(index, 1);

    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "taints",
        value: taints,
      })
    );

    const errors = getState().forms.nodePool.errors;
    const errorRemnants = errors.map((error) =>
      error?.field.includes(`taints`) ? { ...error, result: null } : error
    );

    dispatch(
      addNodePoolFormActions.updateErrors({
        module: "nodePool",
        errors: errorRemnants,
      })
    );
  };
}

export function setTaintsVisibilityChange() {
  return (dispatch, getState) => {
    const { taints = [], withTaints } = getState().forms?.nodePool?.data;
    let newTaints = [...taints];

    if (!withTaints) {
      newTaints.push({ key: "", value: "", effect: null });
    } else {
      newTaints = [];
    }

    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: {
          taints: newTaints,
          withTaints: !withTaints,
        },
      })
    );

    const errors = getState().forms.nodePool.errors;
    const errorRemnants = errors.map((error) =>
      error?.field.includes(`taints`) ? { ...error, result: null } : error
    );

    dispatch(
      addNodePoolFormActions.updateErrors({
        module: "nodePool",
        errors: errorRemnants,
      })
    );
  };
}

export function addNewTaint() {
  return (dispatch, getState) => {
    const taints = [...getState().forms?.nodePool?.data?.taints];
    taints.push({ key: "", value: "", effect: null });

    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "taints",
        value: taints,
      })
    );
  };
}

export function estimateRatePerNodePool(nodePoolGuid, desiredSize) {
  return async (dispatch, getState) => {
    store.dispatch({ type: "RESET_ESTIMATED_RATE" });

    const state = getState();
    const nodePool = getNodePools(state).find(
      (nodePool) => nodePool.guid === nodePoolGuid
    );
    const clusterCloudConfig = getClusterCloudConfig(state);
    const cloudType =
      clusterCloudConfig?.kind || clusterCloudConfig?.metadata?.kind;
    const formData = await POPULATE_FIELDS_MAPPING[cloudType](nodePool);
    formData.size = desiredSize;

    return dispatch(fetchNodePoolEstimatedRate(formData));
  };
}

export function fetchNodePoolEstimatedRate(data) {
  return async (dispatch, getState) => {
    const clusterCloudConfig = getClusterCloudConfig(getState());
    const kind = clusterCloudConfig?.kind || clusterCloudConfig?.metadata?.kind;
    if (kind !== "vsphere") {
      await dispatch(loadNodePoolCloudProperties());
    }
    const payload = PAYLOAD_MAPPING[kind](data);

    let cloudConfig = clusterCloudConfig?.spec?.clusterConfig;

    if (["azure", "aks"].includes(kind)) {
      cloudConfig.aadProfile = {
        ...cloudConfig?.aadProfile,
        adminGroupObjectIDs: cloudConfig?.aadProfile?.adminGroupObjectIDs || [],
      };
    }

    if (kind === "libvirt") {
      payload.cloudConfig = {
        ...payload.cloudConfig,
        placements: (payload?.cloudConfig?.placements || []).filter(
          ({ hostUid }) => !!hostUid
        ),
      };
    }

    if (["edge", "edge-native"].includes(kind)) {
      payload.cloudConfig = {
        ...payload.cloudConfig,
        edgeHosts: (payload?.cloudConfig?.edgeHosts || []).filter(
          ({ hostUid }) => !!hostUid
        ),
      };
    }

    if (["azure", "aks"].includes(kind)) {
      cloudConfig = {
        ...cloudConfig,
        infraLBConfig: {
          ...(cloudConfig?.infraLBConfig || {}),
          apiServerLB: {
            ...(cloudConfig?.infraLBConfig.apiServerLB || {}),
            ipAllocationMethod: "Dynamic",
            type: "Public",
          },
        },
      };
    }

    const ratePayload = {
      cloudConfig,
      machinepoolconfig: [
        {
          cloudConfig: payload.cloudConfig,
          poolConfig: payload.poolConfig,
        },
      ],
    };

    const promise = api.post(
      `v1/spectroclusters/${kind}/rate?periodType=hourly`,
      ratePayload
    );

    dispatch({
      type: "FETCH_NODE_ESTIMATED_RATE",
      promise,
    });

    try {
      await promise;
    } catch (error) {
      notifications.error({
        message: i18n.t("Something went wrong"),
        description: error.message,
      });
      return;
    }

    return promise;
  };
}

export function fetchClusterEstimatedRate() {
  return (dispatch, getState) => {
    const cluster = getCluster(getState());
    const uid = cluster?.metadata?.uid;

    if (!uid) {
      return;
    }

    const promise = api.get(`v1/spectroclusters/${uid}/rate?periodType=hourly`);

    dispatch({
      type: "NODES_FETCH_RATES",
      promise,
    });
  };
}

export function onEditApplianceChange(deviceIndex, value) {
  return (dispatch, getState) => {
    const state = getState();
    const formData = state.forms.nodePool.data;
    const edgeHosts = [...cloneDeep(formData.edgeHosts)];
    const edgeHostToChange = edgeHosts[deviceIndex];
    edgeHostToChange.hostUid = value || "";
    const cluster = getCluster(state);

    if (cluster.spec.cloudType === "libvirt") {
      edgeHostToChange.networks = [];
      edgeHostToChange.networkType = "";
      edgeHostToChange.dataStoragePool = "";
      edgeHostToChange.sourceStoragePool = "";
      edgeHostToChange.targetStoragePool = "";
    }

    dispatch(
      addNodePoolFormActions.batchChange({
        module: "nodePool",
        updates: { edgeHosts, gpuVendor: "", gpuModel: "" },
      })
    );

    dispatch(nodePoolAppliancesFetcher.fetch());
    nodePoolApplianceResourceFetchers.forEach((fetcher) =>
      dispatch(fetcher.key(`${deviceIndex}`).fetch())
    );
  };
}

export function nodePoolAddNewVirtualDevice() {
  return (dispatch, getState) => {
    const edgeHosts = [...getState().forms?.nodePool?.data?.edgeHosts];
    edgeHosts.push({ hostUid: "" });
    dispatch(nodePoolAppliancesFetcher.fetch());

    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "edgeHosts",
        value: edgeHosts,
      })
    );
  };
}

export function removeNodePoolVirtualDevice(deviceIndex) {
  return (dispatch, getState) => {
    const edgeHosts = [...getState().forms?.nodePool?.data?.edgeHosts];
    edgeHosts.splice(deviceIndex, 1);

    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "edgeHosts",
        value: edgeHosts,
      })
    );

    // dispatch(
    //   addNodePoolFormActions.clearFieldErrors({
    //     module: "nodePool",
    //     field: `edgeHosts`,
    //   })
    // );

    // dispatch(
    //   addNodePoolFormActions.validateField({
    //     module: "nodePool",
    //     name: `edgeHosts`,
    //   })
    // );

    nodePoolApplianceResourceFetchers.forEach((fetcher) =>
      dispatch(fetcher.key(`${deviceIndex}`).fetch())
    );
    dispatch(nodePoolAppliancesFetcher.fetch());
  };
}

export function onNodePoolNetworkTypeChange(deviceIndex, value) {
  return (dispatch, getState) => {
    const edgeHosts = [...cloneDeep(getState().forms.nodePool.data.edgeHosts)];
    edgeHosts[deviceIndex].networkType = value;
    edgeHosts[deviceIndex].networks = [];

    dispatch(
      addNodePoolFormActions.onChange({
        module: "nodePool",
        name: "edgeHosts",
        value: edgeHosts,
      })
    );

    dispatch(
      nodePoolApplianceNetworksKeyedFetcher.key(`${deviceIndex}`).fetch()
    );
  };
}
