import _ from "lodash";

async function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const MARK = Symbol("formActions");

export function areFormActions(actions) {
  return actions.__mark === MARK;
}

function throttleValidationErrors(validatorErrors, payload) {
  return async (dispatch, getState) => {
    let errors = [];
    const handler = () => {
      dispatch({ type: "UPDATE_FORM_ERRORS", ...payload, errors });
      errors = [];
    };
    const dispatchErrors = _.throttle(handler, 100, { leading: true });

    for await (const error of validatorErrors) {
      errors = [...errors, error];
      dispatchErrors();
    }

    await wait(100);
    return getState().forms[payload.module].errors;
  };
}

export default function createFormActions({
  validator,
  init = () => Promise.resolve({}),
  submit,
} = {}) {
  const validateField = (data, payload) => {
    return (dispatch) => {
      if (!validator) {
        return Promise.resolve([]);
      }
      const fields = Array.isArray(payload.name)
        ? payload.name
        : [payload.name];
      const validatorErrors = validator.run(data, fields);
      return dispatch(throttleValidationErrors(validatorErrors, payload));
    };
  };

  const validateForm =
    ({ module }) =>
    async (dispatch, getState) => {
      dispatch({ type: "VALIDATE_FORM_START", module });
      const state = getState();
      const { data } = state.forms[module];
      if (!validator) {
        return [];
      }
      const validatorErrors = validator.run(data);
      const errors = await dispatch(
        throttleValidationErrors(validatorErrors, { module })
      );

      dispatch({ type: "VALIDATE_FORM_END", module });
      return errors;
    };

  const actions = {
    onChange: (payload) => async (dispatch, getState) => {
      const state = getState();
      const { errors } = state.forms[payload.module];
      dispatch({ type: "FORM_ON_CHANGE", ...payload });
      if (errors.find((err) => err.field === payload.name)) {
        const data = getState().forms[payload.module].data;
        dispatch(validateField(data, payload));
      }
    },
    validateField: (payload) => async (dispatch, getState) => {
      const state = getState();
      const { data, errors } = state.forms[payload.module];
      const fields = Array.isArray(payload.name)
        ? payload.name
        : [payload.name];
      // if checkErrors is true, then validate only the touched fields which might have outdated error messages
      const fieldsToValidate = payload?.checkErrors
        ? fields?.filter((fieldName) =>
            errors?.some((err) => err.field === fieldName)
          )
        : fields;

      return dispatch(
        validateField(data, { ...payload, name: fieldsToValidate })
      );
    },
    init:
      ({ module, ...rest }) =>
      (dispatch) => {
        const promise = init(rest);
        dispatch({
          type: "FORM_LOAD",
          promise,
          module,
        });

        return promise;
      },
    clearErrors: ({ module }) => ({ type: "FORM_CLEAR_VALIDATIONS", module }),
    clearFieldErrors: ({ module, field }) => ({
      type: "FORM_CLEAR_FIELD_VALIDATIONS",
      module,
      field,
    }),
    updateErrors: ({ module, errors }) => ({
      type: "UPDATE_FORM_ERRORS",
      module,
      errors,
    }),
    validateForm,
    submit:
      ({ module }) =>
      async (dispatch, getState) => {
        const { data, submitting } = getState().forms[module];
        if (submitting) {
          return Promise.reject();
        }
        const errors = await dispatch(validateForm({ module }));

        if (errors.length) {
          return Promise.reject(errors);
        }

        const promise = submit(data);

        dispatch({
          type: "FORM_SUBMIT",
          promise,
          module,
        });

        return promise;
      },
    batchChange({ module, updates }) {
      return function thunk(dispatch) {
        dispatch({
          type: "FORM_BATCH_UPDATE",
          module,
          updates,
        });
      };
    },
  };

  Object.defineProperty(actions, "__mark", {
    enumerable: false,
    writable: false,
    value: MARK,
  });

  return actions;
}
