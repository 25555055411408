import { useEffect } from "react";
import appEnv from "services/app";

function loadScript({ app_id }) {
  const script = document.createElement("script");
  script.id = "intercom-script";

  script.innerHTML = `
    window.intercomSettings = {
      api_base: "https://api-iam.eu.intercom.io",
      app_id: "${app_id}"
    };
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/${app_id}";
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
          l();
        } else if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })();
  `;

  document.body.appendChild(script);
}

export default function IntercomWidget() {
  const env = appEnv.env?.demos?.intercom;
  useEffect(() => {
    loadScript(env);

    document.getElementById("jsd-widget").hidden = true;

    return () => {
      document.getElementById("jsd-widget").hidden = false;
    };
  }, [env]);

  return null;
}
