import ListActions from "modules/list/actions";
import api from "services/api";
import ModalService from "services/modal";
import { AppDeploymentSchema } from "utils/schemas";

export const DEPLOYMENTS_LIST_MODULE = "appDeployments";
export const deleteDeploymentModal = new ModalService();

export const appDeploymentsListActions = new ListActions({
  hasPagination: true,
  initialQuery() {
    return {
      limit: 20,
    };
  },
  fetchData: async (query) => {
    const { offset, limit, continue: continueToken } = query;
    const continueQueryParam = continueToken
      ? `&continue=${continueToken}`
      : "";

    const payload = {
      sort: [
        {
          field: "lastModifiedTimestamp",
          order: "desc",
        },
      ],
    };

    const data = await api.post(
      `v1/dashboard/appDeployments?limit=${limit}&offset=${offset}${continueQueryParam}`,
      payload
    );

    return { ...data, items: data?.appDeployments || [] };
  },
  schema: [AppDeploymentSchema],
});
