import createApi from "./api/create";

const api = createApi();
class ApplicationEnvironment {
  _env = {};

  async initialize() {
    let data = {};
    try {
      data = await api.get(`app.json?${Date.now()}`);
    } catch (err) {}

    this._env = data;
  }

  get env() {
    return this._env;
  }
}

const appEnv = new ApplicationEnvironment();

export default appEnv;
