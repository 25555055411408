import dataFetcher from "modules/dataFetcher";
import api from "services/api";

export default function editorReducer() {
  return {
    name: "editor",
    initialState: {
      mode: "form",
    },
    switchMode(nextState, { mode }) {
      nextState.mode = mode;
    },
  };
}
export const appProfileMacrosFetcher = dataFetcher({
  selectors: ["appProfiles", "macros"],
  fetchData: async () => {
    const data = await api.get(`v1/appProfiles/macros`);

    return (data?.macros || []).map((macro) => macro.name);
  },
});
