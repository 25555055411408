import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Color from "color";

import Layers from "./Layers";
import { TextButton } from "components/ui/Button";

import { white, warning, midGray, error } from "utils/constants/colors";
import { getLayerPrecedence } from "utils/presenters";

import clusterprofile_background from "assets/images/clusterprofile_background.svg";
import ManifestIcon from "assets/icons/manifest.svg?component";
import BackedUpImage from "../BackedUpImage";

const LayerTabsContainer = styled.div`
  width: 288px;
  margin: 0 10px 0;
`;

const Description = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 12px;
  color: ${(props) => (props.isComplete ? "#555" : "#bbb")};
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  font-style: normal;
  font-weight: normal;
`;

const ExclamationIcon = styled(FontAwesomeIcon)`
  color: ${warning};
  margin-left: 5px;
`;

const InvalidPackIcon = styled(FontAwesomeIcon)`
  color: ${error};
  margin-left: 5px;
`;

const LayerTab = styled.div`
  width: 100%;
  height: 48px;
  line-height: 32px;
  padding: 14px;
  align-items: center;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.05em;
  margin: ${(props) => (props.compact ? 2 : 4)}px 0;
  background: ${white};
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-between;
  text-align: right;
  white-space: nowrap;
  color: ${midGray};

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      pointer-events: none;
    `}

  ${TextButton} {
    margin-right: -10px;
    margin-left: 7px;
  }

  ${Description} {
    pointer-events: auto;
    cursor: pointer;
  }

  ${(props) =>
    props.isComplete &&
    css`
      background: #ffffff;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    `}

  ${(props) =>
    props.active &&
    css`
      border: 1px solid ${Color(props.color).alpha(0.7).rgb().string()};
      box-shadow: 0px 0px 6px ${Color(props.color).rgb().fade(0.08).string()};

      ${(props) =>
        props.tabsOnRight &&
        css`
          margin-left: ${(props) => (props.compact ? 3 : 22)}px;
        `}
      ${(props) =>
        !props.tabsOnRight &&
        css`
          margin-left: ${(props) => (props.compact ? -3 : -22)}px;
        `}
    `}

  > .ant-icon {
    font-size: 19px;
  }

  ${(props) =>
    props.isDraft &&
    css`
      border: 1px dashed ${Color(props.color).alpha(0.7).rgb().string()};
    `}

  &:before {
    content: "";
    display: block;
    width: 3px;
    height: 6px;
    background: ${(props) => Color(props.color).alpha(0.7).rgb().string()};
    border-top-left-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 8px;
    left: -3px;
    position: absolute;
  }
  &:after {
    content: "";
    display: block;
    width: 60px;
    height: 1px;
    background: ${(props) => Color(props.color).alpha(0.7).rgb().string()};

    left: -60px;
    position: absolute;
  }

  ${(props) =>
    props.compact &&
    css`
      height: 32px;
      padding: 0 16px;
    `}

  ${(props) =>
    !props.tabsOnRight &&
    css`
      &:before {
        left: auto;
        right: -3px;
        border-top-left-radius: 0px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 0px;
      }
      &:after {
        left: auto;
        right: -60px;
      }
    `}

    transition: background 0.15s ease-in-out, color 0.15s ease-in-out,
    margin-left 0.15s ease-in;
`;

const LogoWrap = styled.div`
  width: 30px;

  > img {
    max-width: 100%;
  }
`;

const EmptyLogo = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 30px;
  background: #ddd;
  margin: 0 5px;
`;

const LayerNameWrap = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: ${(props) => (props.isComplete ? props.color : "#bbb")};
`;

const DescriptionWrap = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;

const MainWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding-right: 24px;

  ${(props) =>
    props.hasBackground &&
    css`
      background-image: url(${clusterprofile_background});
      background-repeat: no-repeat;
      background-size: 100%;
    `}
  ${(props) =>
    !props.noOverflow &&
    css`
      overflow-y: auto;
      min-height: 100%;
    `}
`;

const LayersWrap = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.tabsOnRight ? "row-reverse" : "row")};
`;

const PackManifestIcon = styled(ManifestIcon)`
  height: 14px;
  width: 14px;
  top: 0;
  left: 0;
  position: absolute;
`;

const TooltipTitle = styled.div`
  white-space: nowrap;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  font-style: normal;
  font-weight: normal;
  color: ${(props) => (props.isGreyed ? "#bbb" : "#555")};
`;

const ErrorWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #818ea1;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.09rem;
  padding: 10px;
`;

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
    });
  }

  resetError = () => {
    this.setState({
      hasError: false,
    });
  };

  render() {
    if (this.state.hasError) {
      return (
        <ErrorWrap>
          Something went wrong while rendering the Profile preview
        </ErrorWrap>
      );
    }

    return this.props.children;
  }
}

const ClusterProfilePreview = ({
  layers = [],
  selectedLayer,
  selectLayer,
  addNewLayer,
  isAdding,
  compact = false,
  contentOnly = false,
  validationErrors = [],
  children,
  hasBackground = true,
  noOverflow = false,
  tabsOnRight = false,
}) => {
  const { t } = useTranslation();
  const onSelectTab = (guid) => () => {
    selectLayer && selectLayer(guid);
  };

  const shouldCompact = compact || layers.length > 20;

  function renderLayer(layer, index) {
    const active = selectedLayer?.guid === layer.guid;
    const packErrors =
      validationErrors.find((packError) => packError.name === layer.packName)
        ?.errors || [];

    const layerLogo = layer?.config?.logo || layer?.logo;

    const layerName = layer?.packName || layer?.name || t("Incomplete");
    const layerTag = layer?.tag || "";

    const layerDescription = `${layerName} ${layerTag}`;

    const tabElement = (
      <LayerTab
        data-qa-index={index}
        data-qa="profile-layer"
        onClick={onSelectTab(layer.guid)}
        color={layer.color}
        active={active}
        key={layer.guid}
        isDraft={layer.isDraft}
        compact={shouldCompact}
        isComplete={layer.description}
        disabled={!selectLayer}
        tabsOnRight={tabsOnRight}
      >
        <DescriptionWrap>
          {layerLogo ? (
            <LogoWrap>
              <BackedUpImage src={layerLogo} fallbackSrc={layer.icon} />
            </LogoWrap>
          ) : (
            <EmptyLogo />
          )}
          <Tooltip
            color={white}
            title={
              <TooltipTitle isGreyed={!layer.name || !layer.tag}>
                {layerDescription}
              </TooltipTitle>
            }
          >
            <Description isComplete={!!layer.name || !!layer.tag}>
              {layerDescription}
            </Description>
          </Tooltip>
        </DescriptionWrap>
        <LayerNameWrap isComplete={!!layer.title} color={layer.color}>
          {layer?.manifests?.length > 0 &&
            ![layer?.type, layer?.spec?.type].includes("manifest") && (
              <PackManifestIcon />
            )}
          <span>
            {layer.title || t("Pack type")}
            {packErrors.length > 0 && (
              <ExclamationIcon icon={faExclamationCircle} />
            )}
            {layer.isInvalid && !packErrors.length && (
              <Tooltip title={layer.inValidReason}>
                <InvalidPackIcon icon={faExclamationCircle} />
              </Tooltip>
            )}
          </span>
        </LayerNameWrap>
      </LayerTab>
    );

    if (packErrors.length > 0) {
      return (
        <Tooltip
          title={packErrors.map((error, index) => (
            <div key={`error-${layer.guid}-${index}`}>{error.message}</div>
          ))}
        >
          {tabElement}
        </Tooltip>
      );
    }

    return tabElement;
  }

  const layerTabs = useMemo(() => {
    return [...layers]
      .sort((layerA, layerB) => {
        return getLayerPrecedence(layerA) - getLayerPrecedence(layerB);
      })
      .reverse();
  }, [layers]);

  const content = (
    <LayersWrap tabsOnRight={tabsOnRight} data-qa="layers-section">
      <LayerTabsContainer compact={shouldCompact} offset={0}>
        {layerTabs.map(renderLayer)}
      </LayerTabsContainer>
      <Layers
        layers={layers}
        onSelect={selectLayer}
        selectedLayer={selectedLayer?.guid}
        addNewLayer={addNewLayer}
        isAdding={isAdding}
        compact={shouldCompact}
      />
    </LayersWrap>
  );
  if (contentOnly) {
    return content;
  }

  return (
    <ErrorBoundary>
      <MainWrap hasBackground={hasBackground} noOverflow={noOverflow}>
        {children}
        {content}
      </MainWrap>
    </ErrorBoundary>
  );
};

export default ClusterProfilePreview;
