import {
  cloudAccountFetcher,
  CLOUD_ACCOUNTS_MODULE,
  maasOverlordsFetcher,
  vmWareOverlordsFetcher,
} from "./services";
import { createSelector } from "reselect";
import { getStoreEntity } from "services/store";
import { CloudAccountSchema } from "utils/schemas";

export const getSelectedCloudAccount = createSelector(
  cloudAccountFetcher.selector,
  (fetcherSelector) => fetcherSelector.result
);

export const getSelectedJSONFileList = createSelector(
  (state) => state.forms?.cloudAccounts?.data?.jsonFile,
  (file) => {
    if (!file) return null;
    return [
      {
        ...file,
        uid: file.uid || -1,
        status: file.status || "done",
      },
    ];
  }
);

export const getSystemPrivateGatewayUid = createSelector(
  (state) => state.router.location,
  (state) => state.forms?.cluster?.data,
  (state) => state.forms?.cloudAccounts?.data,
  vmWareOverlordsFetcher.selector,
  maasOverlordsFetcher.selector,
  (
    location,
    clusterFormData,
    cloudAccountFormData,
    { result: vmwareResult },
    { result: maasResult }
  ) => {
    const { pathname } = location;
    let cloudType = cloudAccountFormData?.type;
    if (pathname.includes("/clusters/create")) {
      cloudType = clusterFormData?.accountCloudType;
    }

    let result = cloudType === "maas" ? maasResult : vmwareResult;
    return (
      result?.find((overlord) => overlord.spec.isSystem)?.metadata?.uid || ""
    );
  }
);

export const getAwsCloudAccountPayload = createSelector(
  (state) => state.forms?.cloudAccounts?.data,
  (data) => {
    if (data?.credentialType === "sts") {
      return {
        credentialType: data.credentialType,
        sts: {
          arn: data.arn,
          externalId: data.externalId,
          accountId: data.accountId,
        },
        partition: data.partition,
      };
    }

    return {
      accessKey: data.accessKey?.trim(),
      secretKey: data.secretKey?.trim(),
      partition: data.partition,
    };
  }
);

export const getParsedCloudAccounts = createSelector(
  (state) => state.list?.[CLOUD_ACCOUNTS_MODULE]?.items || {},
  (items) => {
    const accounts = Object.keys(items)
      .flatMap((key) => items?.[key] || [])
      .map((accountGuid) => getStoreEntity(accountGuid, CloudAccountSchema));

    const cloudAccounts = {
      aws: {
        cloudType: "aws",
        accounts: [],
      },
      vsphere: {
        cloudType: "vsphere",
        accounts: [],
      },
      gcp: {
        cloudType: "gcp",
        accounts: [],
      },
      azure: {
        cloudType: "azure",
        accounts: [],
      },
      maas: {
        cloudType: "maas",
        accounts: [],
      },
      openstack: {
        cloudType: "openstack",
        accounts: [],
      },
      tencent: {
        cloudType: "tke",
        accounts: [],
      },
      coxedge: {
        cloudType: "coxedge",
        accounts: [],
      },
    };

    accounts.forEach((account) => {
      (cloudAccounts[account.kind]?.accounts || []).push(account);
    });

    return cloudAccounts;
  }
);
