import { TENANT_LEVEL_CLUSTERS_PROJECT } from "./constants";

export const getKeysWithNoProjectKeyMovedTo = (
  noProjKeyPosition,
  keys,
  keyAccessor
) => {
  const noProjectKeyIdx = keys?.findIndex(
    (key) =>
      key?.[keyAccessor] === TENANT_LEVEL_CLUSTERS_PROJECT.uid ||
      key?.[keyAccessor] === TENANT_LEVEL_CLUSTERS_PROJECT.name
  );

  if (noProjectKeyIdx > -1) {
    return [
      ...(noProjKeyPosition === "front" ? [keys[noProjectKeyIdx]] : []),
      ...keys.slice(0, noProjectKeyIdx),
      ...keys.slice(noProjectKeyIdx + 1, keys.length),
      ...(noProjKeyPosition === "end" ? [keys[noProjectKeyIdx]] : []),
    ];
  }
  return keys;
};
