import React from "react";
import styled from "styled-components";
import { Tooltip } from "antd";

import * as colors from "utils/constants/colors";
import { parseTagsForInput } from "utils/parsers";

const Tag = styled.div`
  max-width: 130px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid ${colors.lightMidGray};
  box-sizing: border-box;
  border-radius: 13px;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  background: ${colors.lightWhite};
  color: ${colors.midGray};
  letter-spacing: 0.05em;
  padding: 4px 12px;
  display: inline-block;
  margin-right: 5px;
`;

const TooltipTag = styled.div`
  font-size: 13px;
  line-height: 21px;
  color: ${colors.white};
`;

function renderFirstTags(keys) {
  return keys.map((tag, index) => {
    if (tag.length > 15) {
      return (
        <Tooltip key={index} title={tag}>
          <Tag>{tag}</Tag>
        </Tooltip>
      );
    }

    return <Tag key={index}>{tag}</Tag>;
  });
}

function renderTagsInTooltip(tag, index) {
  return <TooltipTag key={index}>{tag}</TooltipTag>;
}

const Tags = ({ tags, threshold = 1 }) => {
  const tagsKeys = parseTagsForInput(tags);
  const firstKeys = tagsKeys.slice(0, threshold);
  const lastKeys = tagsKeys.slice(threshold, tagsKeys.length);

  return (
    <div>
      {renderFirstTags(firstKeys)}
      {tagsKeys.length > threshold && (
        <Tooltip placement="right" title={lastKeys.map(renderTagsInTooltip)}>
          <Tag>...</Tag>
        </Tooltip>
      )}
    </div>
  );
};

export default Tags;
