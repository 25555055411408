import dataFetcher from "modules/dataFetcher";
import { nonProjectApi } from "services/api";
import { GENERAL_DATE_FORMAT } from "utils/constants";

export const ORGANIZATION_OVERVIEW_FILTERS_MODULE =
  "organizationOverviewFilters";

export const tenantLevelClustersCostFetcher = dataFetcher({
  selectors: ["tenantLevelClustersCost"],
  fetchData(_, { startTime, endTime } = {}) {
    if (!startTime || !endTime) {
      return;
    }

    return nonProjectApi.post(`v1/dashboard/spectroclusters/cost`, {
      filter: {
        startTime: startTime.format(GENERAL_DATE_FORMAT),
        endTime: endTime.format(GENERAL_DATE_FORMAT),
      },
      options: {
        period: 60 * 24,
        groupBy: "tenant",
      },
    });
  },
});

export const projectsCostFetcher = dataFetcher({
  selectors: [
    "projectsCost",
    (state) =>
      state.forms?.[ORGANIZATION_OVERVIEW_FILTERS_MODULE]?.data?.groupBy,
  ],
  fetchData([_, groupBy], { startTime, endTime } = {}) {
    if (!startTime || !endTime) {
      return;
    }

    return nonProjectApi.post(`v1/dashboard/spectroclusters/cost`, {
      filter: {
        startTime: startTime.format(GENERAL_DATE_FORMAT),
        endTime: endTime.format(GENERAL_DATE_FORMAT),
      },
      options: {
        period: 60 * 24,
        groupBy,
      },
    });
  },
});

export const projectsConsumptionFetcher = dataFetcher({
  selectors: [
    "projectsConsumption",
    (state) =>
      state.forms?.[ORGANIZATION_OVERVIEW_FILTERS_MODULE]?.data?.groupBy,
  ],
  fetchData([_, groupBy], { startTime, endTime } = {}) {
    if (!startTime || !endTime) {
      return;
    }

    const period = 60 * 24;

    return nonProjectApi.post("v1/dashboard/spectroclusters/resources/usage", {
      filter: {
        startTime: startTime.format(GENERAL_DATE_FORMAT),
        endTime: endTime.format(GENERAL_DATE_FORMAT),
        includeMasterMachines: true,
      },
      options: {
        period,
        groupBy,
      },
    });
  },
});

export const tenaneLevelClustersConsumptionFetcher = dataFetcher({
  selectors: ["tenantLevelClustersConsumption"],
  fetchData(_, { startTime, endTime } = {}) {
    if (!startTime || !endTime) {
      return;
    }

    const period = 60 * 24;

    return nonProjectApi.post("v1/dashboard/spectroclusters/resources/usage", {
      filter: {
        startTime,
        endTime,
        includeMasterMachines: true,
      },
      options: {
        period,
        groupBy: "tenant",
      },
    });
  },
});
