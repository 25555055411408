import dataFetcher from "modules/dataFetcher";
import { nonProjectApi } from "services/api";
import history from "services/history";

export const ssoLoginsFetcher = dataFetcher({
  selectors: ["ssoLogins"],
  fetchData: async ([_], orgName) => {
    const apiUrl =
      orgName && orgName !== "root"
        ? `v1/auth/sso/logins?org=${orgName}`
        : "v1/auth/sso/logins";

    const logins = await nonProjectApi.get(apiUrl);

    return logins || [];
  },
});

export const oidcOrgFetcher = dataFetcher({
  selectors: ["oidcOrg", (state) => state.location.params.token],
  fetchData: async ([_, token]) => {
    try {
      return await nonProjectApi.get(`v1/oidc/logins/${token}`);
    } catch (err) {
      history.push(`/auth-error?message=OIDC Token is invalid`);
    }
  },
});

export const ssoAuthProvidersFetcher = dataFetcher({
  selectors: ["providers"],
  fetchData: async () => {
    const providers = await nonProjectApi.get(`v1/auth/sso/providers`);

    return providers || [];
  },
});
